// <МИКСИНЫ>==================================================================
@import "./settings/mixins";

// </МИКСИНЫ>=================================================================

// ФУНКЦИИ====================================================================
@import "./settings/functions";

// </ФУНКЦИИ>=================================================================

// <Подключаем шрифты>========================================================
@import "./settings/fonts";

// </Подключаем шрифты>=======================================================

// <ОБНУЛЕНИЕ>================================================================
@import "./settings/normalize";

// </ОБНУЛЕНИЕ>===============================================================

// <ПЕРЕМЕННЫЕ>===============================================================
$containerWidth: 122rem;

:root {
  // base
  --font-family: "Inter";
  --font-weight: 400;
  --content-width: 117rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;

  // colors
  --clr-default-100: #fff;
  --clr-default-200: rgba(60, 60, 67, 0.85);
  --clr-default-300: #1c0b24;
  --clr-default-400: #3e4462;
  --clr-default-500: #18214d;
  --clr-default-600: #0f2920;
  --clr-default-900: #000;
  --clr-primary-100: #c10fc1;
  --clr-primary-200: #8e3385;
  --clr-primary-300: rgba(193, 15, 193, 0.5);
  --clr-primary-400: #610257;
  --clr-primary-500: #df8943;
}

// </ПЕРЕМЕННЫЕ>==============================================================

// <ОБОЛОЧКИ>================================================================
body {
  font-size: 1.6rem;
  color: var(--clr-default-300);

  &.lock {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    -ms-touch-action: none;
    touch-action: none;
    overscroll-behavior: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;

  @media (max-width: em(992)) {
    overflow: hidden;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: rgb(0 0 0 / 50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition), visibility var(--transition);
  }

  &[data-overlay="true"]::before {
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition), visibility var(--transition);
  }
}

main {
  flex-grow: 1;
}

// </ОБОЛОЧКИ>===============================================================

// <СЕТКА>===================================================================
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

// Libs
@import "./libs/swiper";
@import "./libs/aos";
@import "./libs/tel";
@import "./libs/tel-sprite";

// Libs

// Components - UI
@import "./ui";

// Components - UI

// Components - UI
@import "./components/ui/menu";
@import "./components/ui/logo";
@import "./components/ui/burger";

// Components - UI

// Components - Common
@import "./components/common/header";
@import "./components/common/footer";
@import "./components/common/modal";
@import "./components/common/modal-thanks";
@import "./components/common/cookies";

// Components - Common

// Components - Pages
@import "./components/indexPage/hero";
@import "./components/indexPage/statistics";
@import "./components/indexPage/for";
@import "./components/indexPage/solution";
@import "./components/indexPage/testimonials";
@import "./components/indexPage/start";
@import "./components/indexPage/partners";
@import "./components/indexPage/demo";
@import "./components/indexPage/seen";
@import "./components/indexPage/faq/faq";
@import "./components/indexPage/blog/blog";
@import "./components/indexPage/article/article";
@import "./components/indexPage/price/price";
@import "./components/indexPage/price/fee";
@import "./components/indexPage/about/about";
@import "./components/indexPage/about/our";
@import "./components/indexPage/about/ceo";
@import "./components/indexPage/about/contact";
@import "./components/indexPage/capabilities/capabilities";
@import "./components/indexPage/capabilities/analyse";

// Components - Pages

// </СЕТКА>==================================================================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================

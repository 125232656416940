$burgerMQ: em(1200);

.menu {
  &__list {
    display: flex;
    align-items: center;

    @media (max-width: $burgerMQ) {
      flex-direction: column;
      align-items: initial;
      margin-top: 10rem;
      margin-left: 2.5rem;
      width: 100%;
      padding-block: 4rem;
    }
  }

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-right: 3rem;

      @media (max-width: $burgerMQ) {
        margin-right: 0;
        margin-bottom: 2.5rem;
      }
    }

    a {
      color: var(--clr-default-100);
      transition: color var(--transition);

      &:hover {
        color: var(--clr-primary-100);
        transition: color var(--transition);
      }

      @media (max-width: $burgerMQ) {
        font-weight: 600;
        font-size: 2.8rem;
      }
    }
  }

  @media (max-width: $burgerMQ) {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-300);
    transition: right var(--transition);

    &--active {
      right: 0;
      transition: right var(--transition);
    }
  }
}

.hero {
  position: relative;
  overflow: hidden;
  height: 110vh;
  min-height: 115rem;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-400);

  @media (max-width: em(992)) {
    height: initial;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -13rem;
    z-index: 3;
    border-radius: 5.2rem 5.2rem 0px 0px;
    width: 100%;
    height: 20rem;
    box-shadow: 0px -20px 35px rgba(0, 0, 0, 0.04);
    background-color: var(--clr-default-100);
    pointer-events: none;

    @media (max-width: em(1300)) {
      display: none;
    }
  }

  &__particles {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 100%;

    > * {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    z-index: 1;
    margin-bottom: 13.7rem;
    max-width: 102rem;
  }

  &__title {
    margin-bottom: 2.4rem;
    padding-top: 10rem;
    font-size: fluid-font(7rem, 3.1rem);
    text-align: center;

    @media (max-width: em(992)) {
      padding-top: 5rem;
    }

    @media (max-width: em(575)) {
      padding-top: 3rem;
    }
  }

  &__sibtitle {
    margin-bottom: 4.8rem;
    font-size: fluid-font(2rem, 1.6rem);
    line-height: 150%;
    text-align: center;
  }

  &__button {
    margin: 0 auto;

    @media (max-width: em(575)) {
      min-width: 100%;
    }
  }

  &__img {
    position: absolute;
    left: 0;
    bottom: 4.4rem;
    z-index: 1;
    display: flex;
    width: 100%;

    img {
      margin-right: auto;
      margin-left: auto;
    }

    @media (max-width: em(1300)) {
      bottom: 0;
    }

    @media (max-width: em(1200)) {
      margin-left: 1.5rem;

      img {
        height: 25rem;
        max-width: initial;
        object-position: left top;
      }
    }
  }

  &__lottie {
    position: absolute;
    left: calc((100% - var(--container-width) - 17rem) / 2);
    bottom: 22.4rem;
    z-index: 2;
    width: 100%;
    max-width: 50rem;
    opacity: 0;
    filter: drop-shadow(0px 21.9523px 26.3428px rgba(0, 0, 0, 0.102));
    transform: rotate(-10deg);
    transition: opacity 0.4s;

    @media (max-width: em(1450)) {
      left: calc((100% - var(--container-width) - 10rem) / 2);
    }

    @media (max-width: em(1200)) {
      left: 2.5rem !important;
      bottom: 8.4rem;
      transform: rotate(-3deg);
    }

    @media (max-width: em(576)) {
      bottom: 12.4rem;
      max-width: 24rem;
    }
  }

  @media (max-width: em(1200)) {
    padding-bottom: 34rem;
    height: auto;
    min-height: initial;
  }

  @media (max-width: em(576)) {
    padding-bottom: 20rem;
  }
}

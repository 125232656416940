.blog {
  margin-bottom: 20rem;

  @media (max-width: em(992)) {
    margin-bottom: 10rem;
  }

  @media (max-width: em(575)) {
    margin-bottom: 5rem;
  }

  &__title {
    padding-bottom: 8rem;

    @media (max-width: em(768)) {
      padding-bottom: 3rem;
    }
  }

  &__items {
    display: grid;
    grid-gap: 5rem 2.3rem;
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));

    @media (max-width: em(575)) {
      grid-gap: 3rem;
    }
  }

  &__img {
    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;
    padding-bottom: 67%;
    width: 100%;
    max-width: 39rem;

    @media (max-width: em(767)) {
      max-width: initial;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 1.6rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__date {
    padding-bottom: 1rem;
    font-size: 1.2rem;
    color: var(--clr-default-200);
  }

  &__item-title {
    padding-bottom: 1rem;
    font-size: fluid-font(2.6rem, 1.8rem);
    color: var(--clr-default-300);
  }

  &__item-text {
    line-height: 150%;
    color: var(--clr-default-300);
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7rem;

    @media (max-width: em(575)) {
      margin-top: 3rem;
    }
  }

  &__pagination-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid #c4c4c4;
    border-radius: 0.4rem;
    min-width: 4rem;
    min-height: 4rem;
    transition: background-color var(--transition);

    svg {
      width: 1.6rem;
      height: 1.4rem;
      fill: var(--clr-default-300);
      transition: fill var(--transition);
    }

    &--right {
      svg {
        transform: rotate(-180deg);
      }
    }

    &--disabled {
      pointer-events: none;
      svg {
        fill: #c4c4c4;
      }
    }

    @media (hover) {
      &:hover {
        background-color: var(--clr-primary-100);
        transition: background-color var(--transition), fill var(--transition);

        svg {
          fill: var(--clr-default-100);
        }
      }
    }
  }

  &__pagination-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 2.6rem;
    margin-left: 2.6rem;

    @media (max-width: em(575)) {
      margin-right: 1rem;
      margin-left: 1rem;
    }
  }

  &__pagination-item {
    height: 4.3rem;
    min-width: 2.8rem;

    &:not(:last-child) {
      margin-right: 1.2rem;

      @media (max-width: em(575)) {
        margin-right: 0.8rem;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-weight: 600;
      color: var(--clr-default-300);
    }

    &--active {
      a {
        color: var(--clr-primary-100);
      }
      border-bottom: 0.1rem solid var(--clr-primary-100);
    }
  }

  &__form-title {
    padding-bottom: 3rem;
    font-size: fluid-font(3.6rem, 2.4rem);
    line-height: 125%;
  }

  &__form-inner {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  &__form-warning {
    letter-spacing: -0.4px;
    color: var(--clr-default-400);
    opacity: 0.5;
  }

  &__form-input {
    border-radius: 0.8rem;
    padding: 1.4rem 2rem 1.4rem 1.6rem;
    min-width: 100%;
    background-color: #eff2f6;

    &-wrapper {
      margin-bottom: 1.6rem;
    }

    &::placeholder {
      color: #c4c4c4;
    }
  }

  &__form-btn {
    display: flex;
    justify-content: center;
    border-radius: 0.8rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    min-width: 100%;
    font-weight: 600;
    font-size: 1.8rem;
    color: var(--clr-default-100);
    background-color: var(--clr-primary-100);
    transition: background-color var(--transition);
    cursor: pointer;

    &:hover {
      background-color: #e200e2;
      transition: background-color var(--transition);
    }
  }

  &__button {
    margin: 0 auto;
  }
}

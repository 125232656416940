.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  padding-top: 15rem;

  @media (max-width: em(992)) {
    padding-top: 9rem;
  }
}

.title {
  font-weight: 600;
  font-size: fluid-font(6rem, 4.2rem);
  line-height: 120%;
}

.main__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.2rem;
  padding-top: 2rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  min-width: 24rem;
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.13);
  font-weight: 600;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-100);
  transition: background-color var(--transition);

  &:hover {
    background-color: #e200e2;
    transition: background-color var(--transition);
  }

  @media (max-width: em(575)) {
    min-width: 100%;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 5rem;

  @media (max-width: em(992)) {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }

  &__item {
    position: relative;

    &.active a {
      color: var(--clr-default-300);
    }

    &:not(:last-child) {
      margin-right: 1.5rem;

      &::after {
        content: "-";
        position: absolute;
        right: -1.1rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__item a {
    color: var(--clr-default-200);
  }
}

.go-up {
  position: fixed;
  right: 8.5rem;
  bottom: 8rem;
  z-index: 4;
  border-radius: 50%;
  min-width: 6rem;
  min-height: 6rem;
  background-color: var(--clr-primary-200);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);

  @media (max-width: em(1200)) {
    right: 3.5rem;
    bottom: 8.5rem;
  }

  @media (max-width: em(576)) {
    right: 1.5rem;
    top: 5rem;
    bottom: initial;
    min-width: 4.5rem;
    min-height: 4.5rem;
  }

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 2.4rem;
    height: 2.4rem;
    background-image: url(../img/footer/arrow-top.svg);
    background-size: cover;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
    transition: transform var(--transition);
  }

  @media (hover) {
    &:hover {
      &:before {
        transform: translate(-50%, -65%);
        transition: transform var(--transition);
      }
    }
  }

  &--active {
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition), visibility var(--transition);
  }
}

.validate__error {
  margin-top: 0.3rem;
  font-size: 1.4rem;
  color: rgb(184, 17, 17);
}

.just-validate-error-field {
  border: 1px solid rgb(184, 17, 17);
}

.faq {
  margin-bottom: 20rem;

  @media (max-width: em(992)) {
    margin-bottom: 10rem;
  }

  @media (max-width: em(575)) {
    margin-bottom: 5rem;
  }

  &__title {
    padding-bottom: 8rem;

    @media (max-width: em(992)) {
      padding-bottom: 3rem;
    }
  }

  &__body {
    margin-bottom: 8rem;
  }

  &__wrapper {
    &:not(:last-child) {
      margin-bottom: 2rem;

      @media (max-width: em(575)) {
        margin-bottom: 1rem;
      }
    }

    &.active {
      .faq__question {
        &::after {
          transform: translateY(-50%) rotate(-45deg);
          transition: transform var(--transition);
        }
      }

      .faq__answer {
        transition: max-height var(--transition), padding var(--transition);
      }
    }
  }

  &__question {
    position: relative;
    padding-top: 2rem;
    padding-right: 4rem;
    padding-bottom: 2rem;
    width: 100%;
    font-weight: 600;
    font-size: fluid-font(2.6rem, 2rem);

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 2.3rem;
      height: 2.3rem;
      background-image: url(../img/faq/plus.svg);
      background-size: cover;
      background-repeat: no-repeat;
      transform: translateY(-50%);
      transition: transform var(--transition);
    }

    @media (max-width: em(575)) {
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-weight: 500;
    }
  }

  &__answer {
    overflow: hidden;
    max-height: 0;
    transition: max-height var(--transition);

    p {
      line-height: 150%;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    ul {
      list-style: disc;
      padding-left: 2rem;

      li {
        line-height: 150%;

        span {
          font-weight: 600;
        }

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }

  &__list-mb {
    margin-bottom: 2rem;
  }
}

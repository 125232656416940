.demo {
  overflow: hidden;
  margin-top: 20rem;
  height: 90vh;

  @media (max-width: em(992)) {
    margin-top: 10rem;
    height: initial;
  }

  @media (max-width: em(575)) {
    margin-top: 5rem;
    height: auto;
    background-color: var(--clr-primary-400);
  }

  .container {
    @media (min-width: em(576)) {
      position: sticky;
      top: 4rem;
    }

    @media (max-width: em(575)) {
      position: static;
    }
  }

  &__wrapper {
    position: relative;
    border-radius: 2.4rem;
    padding-top: 7rem;
    padding-right: 3rem;
    padding-bottom: 7rem;
    padding-left: 10rem;
    color: var(--clr-default-100);
    background-color: var(--clr-primary-400);

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 52rem;
      min-height: 48rem;
      background-image: url(../img/demo/bg.svg);
      background-position: right;
      background-size: contain;
      background-repeat: no-repeat;

      @media (max-width: em(1200)) {
        min-width: 50%;
        min-height: 50%;
      }

      @media (max-width: em(575)) {
        left: 50%;
        right: initial;
        min-width: 36rem;
        min-height: 40rem;
        transform: translateX(-50%);
      }
    }

    @media (max-width: em(1200)) {
      padding-top: 3rem;
      padding-bottom: 16rem;
      padding-left: 3rem;
    }

    @media (max-width: em(575)) {
      padding-top: 5rem;
      padding-right: 1rem;
      padding-bottom: 40rem;
      padding-left: 1rem;
    }
  }

  &__title {
    padding-bottom: 1.5rem;
    max-width: 60rem;
    font-size: 6rem;

    @media (max-width: em(1200)) {
      max-width: initial;
    }

    @media (max-width: em(575)) {
      font-size: 4rem;
    }
  }

  &__subtitle {
    padding-bottom: 5rem;
    max-width: 60rem;
    font-weight: 400;
    line-height: 150%;

    @media (max-width: em(1200)) {
      max-width: initial;
    }
  }

  &__button {
    @media (max-width: em(575)) {
      min-width: 100%;
    }
  }
}

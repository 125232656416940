.contact {
    padding-bottom: 20rem;

    @media (max-width: em(992)) {
        padding-bottom: 10rem;
    }

    @media (max-width: em(575)) {
        padding-bottom: 5rem;
    }

    &__title {
        padding-bottom: 8rem;

        @media (max-width: em(992)) {
            padding-bottom: 6rem;
        }

        @media (max-width: em(575)) {
            padding-bottom: 4rem;
        }
    }

    &__wrapper {
        display: flex;

        @media (max-width: em(992)) {
            flex-direction: column;
        }
    }

    &__thanks {
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 2.4rem;
        padding: 6rem 5rem;
        max-width: 60rem;
        box-shadow: 0px -8px 35px rgba(0, 0, 0, 0.03), 1px 26px 35px rgba(66, 66, 66, 0.08);
        background-color: var(--clr-default-100);

        .thanks__subtitle {
            text-align: center;
        }

        @media (max-width: em(992)) {
            max-width: initial;
        }

        @media (max-width: em(575)) {
            padding: 3rem 0;
            box-shadow: initial;
        }
    }

    &__form {
        border-radius: 2.4rem;
        padding: 6rem 5rem;
        max-width: 60rem;
        box-shadow: 0px -8px 35px rgba(0, 0, 0, 0.03), 1px 26px 35px rgba(66, 66, 66, 0.08);
        color: var(--clr-default-300);
        background-color: var(--clr-default-100);

        @media (max-width: em(992)) {
            max-width: initial;
        }

        @media (max-width: em(575)) {
            padding: 3rem 0;
            box-shadow: initial;
        }

        &-input + &-input {
            margin-top: 2.5rem;
        }
    }

    &__form-title {
        margin-bottom: 3rem;
        padding-bottom: 1.6rem;
        font-weight: 600;
        font-size: fluid-font(3.6rem, 2.6rem);
    }

    &__name {
        border-radius: 0.8rem;
        padding: 1.6rem;
        min-width: 100%;
        background-color: #f8f8f8;

        &:focus {
            outline: 0.2rem solid var(--clr-primary-100);
        }
    }

    &__mail {
        border-radius: 0.8rem;
        padding: 1.6rem;
        min-width: 100%;
        background-color: #f8f8f8;

        &:focus {
            outline: 0.2rem solid var(--clr-primary-100);
        }
    }

    &__textarea {
        border-radius: 0.8rem;
        padding: 1.6rem;
        min-width: 100%;
        min-height: 15rem;
        background-color: #f8f8f8;
        resize: none;

        &:focus {
            outline: 0.2rem solid var(--clr-primary-100);
        }
    }

    &__option {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__input {
        position: absolute;
        left: 2rem;
        width: 1px;
        height: 1px;
    }

    &__box {
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        border: 1px solid var(--clr-primary-100);
        border-radius: 0.4rem;
        width: 2.8rem;
        height: 2.8rem;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-color: var(--clr-default-100);
        @media (max-width: em(575)) {
            background-color: var(--clr-default-300);
        }
    }

    &__input:checked + &__box {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.81818L4.125 8L11 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
        background-color: var(--clr-primary-100);
    }

    &__policy {
        margin-top: 2.5rem;
        margin-bottom: 5rem;
        @media (max-width: em(575)) {
            margin-bottom: 2rem;
        }
    }

    &__policy-text {
        position: relative;
        padding-left: 4rem;
        min-height: 3rem;
        font-size: fluid-font(1.6rem, 1.4rem);

        a {
            text-decoration: underline;
            color: var(--clr-primary-500);
        }

        @media (max-width: em(575)) {
            padding-left: 4rem;
        }
    }

    &__submit {
        min-width: 100%;
    }

    &__info {
        margin-top: 6rem;
        margin-left: 12rem;

        @media (max-width: em(992)) {
            margin-top: 3rem;
            margin-left: 0;
        }
    }

    &__phone {
        padding-bottom: 5rem;

        p {
            padding-bottom: 1.2rem;
            color: var(--clr-default-200);
        }

        a {
            font-weight: 600;
            font-size: fluid-font(2.6rem, 1.8rem);
            color: var(--clr-default-300);
        }

        @media (max-width: em(575)) {
            padding-bottom: 2rem;
        }
    }

    &__mail-left {
        padding-bottom: 5rem;

        p {
            padding-bottom: 1.2rem;
            color: var(--clr-default-200);
        }

        a {
            font-weight: 600;
            font-size: fluid-font(2.6rem, 1.8rem);
            color: var(--clr-default-300);
        }

        @media (max-width: em(575)) {
            padding-bottom: 2rem;
        }
    }

    &__social {
        p {
            padding-bottom: 1.2rem;
            color: var(--clr-default-200);
        }
    }

    &__icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        min-height: 13rem;
        max-width: 24rem;

        li a svg {
            fill: var(--clr-default-300);
        }

        li:not(:last-child) {
            margin-right: 3rem;
        }

        @media (max-width: em(992)) {
            min-height: 14rem;
        }
    }

    .footer__icons {
        margin: 0;

        @media (max-width: em(992)) {
            display: flex;
        }
    }
}

/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline: auto 1px;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "Inter";
  --font-weight: 400;
  --content-width: 117rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;
  --clr-default-100: #fff;
  --clr-default-200: rgba(60, 60, 67, 0.85);
  --clr-default-300: #1c0b24;
  --clr-default-400: #3e4462;
  --clr-default-500: #18214d;
  --clr-default-600: #0f2920;
  --clr-default-900: #000;
  --clr-primary-100: #c10fc1;
  --clr-primary-200: #8e3385;
  --clr-primary-300: rgba(193, 15, 193, 0.5);
  --clr-primary-400: #610257;
  --clr-primary-500: #df8943;
}

body {
  font-size: 1.6rem;
  color: var(--clr-default-300);
}

body.lock {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  -ms-touch-action: none;
  touch-action: none;
  overscroll-behavior: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.swiper {
  position: relative;
  z-index: 1;
  list-style: none;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  /* Fix of Webkit flickering */
}

.swiper-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-pointer-events {
  touch-action: pan-y;
}

.swiper-pointer-events.swiper-vertical {
  touch-action: pan-x;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

/* Auto Height */

.swiper-autoheight,
.swiper-autoheight .swiper-slide {
  height: auto;
}

.swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}

.swiper-centered > .swiper-wrapper::before {
  content: "";
  flex-shrink: 0;
  order: 9999;
}

.swiper-centered.swiper-horizontal > .swiper-wrapper > .swiper-slide:first-child {
  margin-inline-start: var(--swiper-centered-offset-before);
}

.swiper-centered.swiper-horizontal > .swiper-wrapper::before {
  height: 100%;
  width: var(--swiper-centered-offset-after);
}

.swiper-centered.swiper-vertical > .swiper-wrapper > .swiper-slide:first-child {
  margin-block-start: var(--swiper-centered-offset-before);
}

.swiper-centered.swiper-vertical > .swiper-wrapper::before {
  width: 100%;
  height: var(--swiper-centered-offset-after);
}

.swiper-centered > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: center center;
}

[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
  transition-duration: 0.1s;
}

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s;
}

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
  transition-duration: 0.15s;
}

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: 0.15s;
}

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
  transition-duration: 0.2s;
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: 0.2s;
}

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
  transition-duration: 0.25s;
}

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s;
}

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
  transition-duration: 0.3s;
}

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: 0.3s;
}

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
  transition-duration: 0.35s;
}

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: 0.35s;
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s;
}

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
  transition-duration: 0.45s;
}

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: 0.45s;
}

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
  transition-duration: 0.5s;
}

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: 0.5s;
}

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
  transition-duration: 0.55s;
}

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: 0.55s;
}

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
  transition-duration: 0.6s;
}

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: 0.6s;
}

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
  transition-duration: 0.65s;
}

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: 0.65s;
}

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
  transition-duration: 0.7s;
}

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: 0.7s;
}

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
  transition-duration: 0.75s;
}

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: 0.75s;
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s;
}

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: 0.8s;
}

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
  transition-duration: 0.85s;
}

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: 0.85s;
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: 0.9s;
}

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
  transition-duration: 0.95s;
}

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: 0.95s;
}

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

.iti {
  position: relative;
}

.iti * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input,
.iti input[type=text],
.iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-right: 0;
  margin-bottom: 0 !important;
  padding-right: 36px;
}

.iti__flag-container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.iti__selected-flag {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
  padding: 0 6px 0 8px;
  min-height: 5rem;
}

.iti__arrow {
  margin-left: 6px;
  border-top: 4px solid #555;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  width: 0;
  height: 0;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  overflow-y: scroll;
  margin: 0 0 0 -1px;
  border: 1px solid #ccc;
  padding: 0;
  max-height: 200px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  text-align: left;
  background-color: var(--clr-default-100);
  color: var(--clr-default-900);
  -webkit-overflow-scrolling: touch;
}

.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

.iti__flag-box {
  display: inline-block;
  width: 20px;
}

.iti__divider {
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.iti__country {
  outline: none;
  padding: 5px 10px;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type=text],
.iti--allow-dropdown input[type=tel],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type=text],
.iti--separate-dial-code input[type=tel] {
  margin-left: 0;
  padding-right: 6px;
  padding-left: 52px;
}

.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
  left: 0;
  right: auto;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}

.iti--container {
  position: absolute;
  left: -1000px;
  top: -1000px;
  z-index: 1060;
  padding: 1px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti__flag-box,
.iti__country-name,
.iti__dial-code {
  vertical-align: middle;
}

.iti__flag-box,
.iti__country-name {
  margin-right: 6px;
}

.iti-mobile .iti__country-list {
  width: 100%;
  max-height: 100%;
}

.iti-mobile .iti__country {
  padding: 10px;
  line-height: 1.5em;
}

.iti-mobile .iti--container {
  position: fixed;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
}

.iti__flag {
  width: 20px;
}

.iti__flag.iti__be {
  width: 18px;
}

.iti__flag.iti__ch {
  width: 15px;
}

.iti__flag.iti__mc {
  width: 19px;
}

.iti__flag.iti__ne {
  width: 18px;
}

.iti__flag.iti__np {
  width: 13px;
}

.iti__flag.iti__va {
  width: 15px;
}

.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px;
}

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px;
}

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px;
}

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px;
}

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px;
}

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px;
}

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px;
}

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px;
}

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px;
}

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px;
}

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px;
}

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px;
}

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px;
}

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px;
}

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px;
}

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px;
}

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px;
}

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px;
}

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px;
}

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px;
}

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px;
}

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px;
}

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px;
}

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px;
}

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px;
}

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px;
}

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px;
}

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px;
}

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px;
}

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px;
}

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px;
}

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px;
}

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px;
}

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px;
}

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px;
}

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px;
}

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px;
}

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px;
}

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px;
}

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px;
}

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px;
}

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px;
}

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px;
}

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px;
}

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px;
}

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px;
}

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px;
}

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px;
}

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px;
}

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px;
}

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px;
}

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px;
}

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px;
}

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px;
}

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px;
}

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px;
}

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px;
}

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px;
}

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px;
}

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px;
}

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px;
}

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px;
}

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px;
}

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px;
}

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px;
}

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px;
}

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px;
}

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px;
}

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px;
}

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px;
}

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px;
}

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px;
}

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px;
}

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px;
}

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px;
}

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px;
}

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px;
}

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px;
}

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px;
}

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px;
}

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px;
}

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px;
}

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px;
}

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px;
}

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px;
}

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px;
}

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px;
}

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px;
}

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px;
}

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px;
}

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px;
}

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px;
}

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px;
}

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px;
}

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px;
}

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px;
}

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px;
}

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px;
}

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px;
}

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px;
}

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px;
}

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px;
}

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px;
}

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px;
}

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px;
}

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px;
}

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px;
}

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px;
}

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px;
}

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px;
}

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px;
}

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px;
}

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px;
}

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px;
}

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px;
}

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px;
}

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px;
}

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px;
}

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px;
}

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px;
}

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px;
}

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px;
}

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px;
}

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px;
}

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px;
}

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px;
}

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px;
}

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px;
}

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px;
}

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px;
}

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px;
}

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px;
}

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px;
}

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px;
}

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px;
}

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px;
}

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px;
}

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px;
}

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px;
}

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px;
}

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px;
}

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px;
}

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px;
}

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px;
}

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px;
}

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px;
}

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px;
}

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px;
}

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px;
}

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px;
}

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px;
}

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px;
}

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px;
}

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px;
}

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px;
}

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px;
}

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px;
}

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px;
}

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px;
}

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px;
}

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px;
}

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px;
}

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px;
}

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px;
}

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px;
}

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px;
}

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px;
}

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px;
}

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px;
}

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px;
}

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px;
}

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px;
}

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px;
}

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px;
}

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px;
}

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px;
}

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px;
}

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px;
}

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px;
}

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px;
}

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px;
}

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px;
}

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px;
}

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px;
}

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px;
}

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px;
}

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px;
}

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px;
}

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px;
}

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px;
}

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px;
}

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px;
}

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px;
}

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px;
}

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px;
}

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px;
}

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px;
}

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px;
}

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px;
}

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px;
}

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px;
}

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px;
}

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px;
}

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px;
}

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px;
}

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px;
}

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px;
}

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px;
}

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px;
}

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px;
}

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px;
}

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px;
}

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px;
}

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px;
}

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px;
}

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px;
}

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px;
}

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px;
}

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px;
}

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px;
}

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px;
}

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px;
}

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px;
}

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px;
}

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px;
}

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px;
}

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px;
}

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px;
}

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px;
}

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px;
}

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px;
}

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px;
}

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px;
}

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px;
}

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px;
}

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px;
}

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px;
}

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px;
}

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px;
}

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px;
}

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px;
}

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px;
}

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px;
}

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px;
}

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px;
}

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px;
}

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px;
}

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px;
}

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px;
}

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px;
}

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px;
}

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px;
}

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px;
}

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px;
}

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px;
}

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px;
}

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px;
}

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px;
}

.iti__flag {
  height: 15px;
  box-shadow: 0 0 1px 0 #888;
  background-image: url("../img/flags.png");
  background-position: 20px 0;
  background-repeat: no-repeat;
  background-color: #dbdbdb;
}

.iti__flag.iti__np {
  background-color: transparent;
}

.iti__flag {
  background-image: url("../img/flags/flags.png");
}

.iti__flag {
  width: 20px;
}

.iti__flag.iti__be {
  width: 18px;
}

.iti__flag.iti__ch {
  width: 15px;
}

.iti__flag.iti__mc {
  width: 19px;
}

.iti__flag.iti__ne {
  width: 18px;
}

.iti__flag.iti__np {
  width: 13px;
}

.iti__flag.iti__va {
  width: 15px;
}

.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px;
}

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px;
}

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px;
}

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px;
}

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px;
}

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px;
}

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px;
}

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px;
}

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px;
}

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px;
}

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px;
}

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px;
}

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px;
}

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px;
}

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px;
}

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px;
}

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px;
}

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px;
}

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px;
}

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px;
}

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px;
}

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px;
}

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px;
}

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px;
}

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px;
}

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px;
}

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px;
}

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px;
}

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px;
}

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px;
}

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px;
}

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px;
}

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px;
}

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px;
}

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px;
}

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px;
}

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px;
}

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px;
}

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px;
}

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px;
}

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px;
}

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px;
}

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px;
}

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px;
}

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px;
}

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px;
}

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px;
}

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px;
}

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px;
}

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px;
}

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px;
}

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px;
}

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px;
}

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px;
}

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px;
}

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px;
}

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px;
}

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px;
}

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px;
}

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px;
}

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px;
}

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px;
}

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px;
}

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px;
}

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px;
}

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px;
}

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px;
}

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px;
}

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px;
}

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px;
}

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px;
}

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px;
}

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px;
}

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px;
}

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px;
}

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px;
}

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px;
}

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px;
}

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px;
}

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px;
}

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px;
}

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px;
}

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px;
}

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px;
}

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px;
}

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px;
}

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px;
}

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px;
}

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px;
}

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px;
}

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px;
}

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px;
}

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px;
}

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px;
}

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px;
}

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px;
}

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px;
}

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px;
}

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px;
}

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px;
}

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px;
}

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px;
}

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px;
}

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px;
}

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px;
}

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px;
}

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px;
}

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px;
}

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px;
}

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px;
}

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px;
}

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px;
}

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px;
}

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px;
}

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px;
}

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px;
}

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px;
}

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px;
}

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px;
}

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px;
}

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px;
}

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px;
}

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px;
}

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px;
}

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px;
}

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px;
}

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px;
}

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px;
}

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px;
}

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px;
}

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px;
}

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px;
}

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px;
}

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px;
}

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px;
}

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px;
}

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px;
}

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px;
}

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px;
}

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px;
}

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px;
}

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px;
}

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px;
}

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px;
}

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px;
}

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px;
}

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px;
}

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px;
}

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px;
}

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px;
}

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px;
}

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px;
}

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px;
}

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px;
}

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px;
}

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px;
}

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px;
}

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px;
}

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px;
}

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px;
}

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px;
}

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px;
}

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px;
}

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px;
}

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px;
}

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px;
}

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px;
}

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px;
}

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px;
}

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px;
}

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px;
}

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px;
}

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px;
}

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px;
}

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px;
}

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px;
}

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px;
}

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px;
}

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px;
}

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px;
}

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px;
}

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px;
}

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px;
}

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px;
}

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px;
}

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px;
}

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px;
}

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px;
}

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px;
}

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px;
}

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px;
}

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px;
}

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px;
}

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px;
}

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px;
}

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px;
}

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px;
}

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px;
}

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px;
}

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px;
}

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px;
}

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px;
}

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px;
}

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px;
}

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px;
}

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px;
}

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px;
}

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px;
}

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px;
}

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px;
}

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px;
}

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px;
}

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px;
}

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px;
}

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px;
}

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px;
}

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px;
}

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px;
}

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px;
}

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px;
}

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px;
}

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px;
}

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px;
}

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px;
}

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px;
}

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px;
}

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px;
}

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px;
}

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px;
}

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px;
}

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px;
}

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px;
}

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px;
}

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px;
}

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px;
}

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px;
}

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px;
}

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px;
}

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px;
}

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px;
}

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px;
}

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px;
}

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px;
}

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px;
}

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px;
}

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px;
}

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px;
}

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px;
}

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px;
}

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px;
}

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px;
}

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px;
}

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px;
}

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px;
}

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px;
}

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px;
}

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px;
}

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.section {
  padding-top: 15rem;
}

.title {
  font-weight: 600;
  font-size: clamp(
    4.2rem,
    3.56rem + 2vw,
    6rem
  );
  line-height: 120%;
}

.main__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.2rem;
  padding-top: 2rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  min-width: 24rem;
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.13);
  font-weight: 600;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-100);
  transition: background-color var(--transition);
}

.main__btn:hover {
  background-color: #e200e2;
  transition: background-color var(--transition);
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 5rem;
}

.breadcrumbs__item {
  position: relative;
}

.breadcrumbs__item.active a {
  color: var(--clr-default-300);
}

.breadcrumbs__item:not(:last-child) {
  margin-right: 1.5rem;
}

.breadcrumbs__item:not(:last-child)::after {
  content: "-";
  position: absolute;
  right: -1.1rem;
  top: 50%;
  transform: translateY(-50%);
}

.breadcrumbs__item a {
  color: var(--clr-default-200);
}

.go-up {
  position: fixed;
  right: 8.5rem;
  bottom: 8rem;
  z-index: 4;
  border-radius: 50%;
  min-width: 6rem;
  min-height: 6rem;
  background-color: var(--clr-primary-200);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.go-up::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(../img/footer/arrow-top.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  transition: transform var(--transition);
}

.go-up--active {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

.validate__error {
  margin-top: 0.3rem;
  font-size: 1.4rem;
  color: rgb(184, 17, 17);
}

.just-validate-error-field {
  border: 1px solid rgb(184, 17, 17);
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__item {
  position: relative;
}

.menu__item:not(:last-child) {
  margin-right: 3rem;
}

.menu__item a {
  color: var(--clr-default-100);
  transition: color var(--transition);
}

.menu__item a:hover {
  color: var(--clr-primary-100);
  transition: color var(--transition);
}

.logo {
  margin-right: 2rem;
  max-width: 17rem;
}

.burger {
  display: none;
}

.header {
  display: flex;
  align-items: center;
  min-height: 7rem;
  background-color: var(--clr-default-300);
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__buttons {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.header__sub {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 1.5rem;
  border: 1px solid var(--clr-primary-100);
  border-radius: 0.8rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  min-width: 13rem;
  min-height: 4.7rem;
  max-width: 13rem;
  font-weight: 600;
  text-align: center;
  color: var(--clr-default-100);
  transition: background-color var(--transition);
}

.header__sub:hover {
  background-color: #e200e2;
  transition: background-color var(--transition);
}

.header__free {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.8rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  min-width: 16rem;
  min-height: 4.7rem;
  max-width: 16rem;
  font-weight: 600;
  text-align: center;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-300);
  transition: background-color var(--transition);
}

.header__lang {
  position: relative;
  display: flex;
  margin-left: auto;
  border: 1px solid rgba(60, 60, 67, 0.85);
  border-radius: 4px;
  padding: 0.5rem;
  font-weight: 500;
  font-size: clamp(
    1.6rem,
    1.5288888889rem + 0.2222222222vw,
    1.8rem
  );
  color: var(--clr-default-100);
  background-color: #1c0b24;
  cursor: pointer;
}

.header__lang-dropdown {
  position: absolute;
  left: -0.1rem;
  right: 0;
  top: 100%;
  z-index: 2;
  border: 1px solid rgba(60, 60, 67, 0.85);
  border-radius: 0px 0px 4px 4px;
  padding: 0.5rem;
  width: calc(100% + 0.2rem);
  background-color: #1c0b24;
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.header__lang-dropdown-item a {
  display: flex;
  align-items: center;
  color: var(--clr-default-100);
  transition: color var(--transition);
}

.header__lang-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-right: 0.7rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.header__lang-img img {
  border-radius: 50%;
}

.header__lang--current {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-right: 2rem;
}

.header__lang--current::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 1.9rem;
  height: 1.9rem;
  background-image: url(../img/header/tringle.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%) rotate(0);
  transition: transform var(--transition);
}

.header__lang:hover,
.header__lang:focus {
  border-radius: 4px 4px 0 0;
}

.header__lang:focus .header__lang-dropdown,
.header__lang:hover .header__lang-dropdown {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

.header__lang:focus .header__lang--current::before,
.header__lang:hover .header__lang--current::before {
  transform: translateY(-50%) rotate(-180deg);
  transition: transform var(--transition);
}

.footer {
  display: flex;
  align-items: center;
  padding-top: 10rem;
  padding-bottom: 14rem;
  background-color: var(--clr-default-300);
}

.footer__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__content {
  margin-right: 2rem;
}

.footer__logo {
  margin-bottom: 2rem;
  max-width: 17rem;
}

.footer__text {
  margin-bottom: 6rem;
  max-width: 58rem;
  line-height: 150%;
  color: var(--clr-default-100);
}

.footer__social-mobile {
  display: none;
}

.footer__list {
  display: flex;
  flex-wrap: wrap;
}

.footer__item:not(:last-child) {
  margin-right: 3rem;
  margin-bottom: 1rem;
}

.footer__item a,
.footer__item button {
  font-weight: 600;
  color: var(--clr-default-100);
  transition: color var(--transition);
}

.footer__item a:hover,
.footer__item button:hover {
  color: var(--clr-primary-100);
  transition: color var(--transition);
}

.footer__icons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10.2rem;
  max-width: 34rem;
}

.footer__icon a svg {
  position: relative;
  border-radius: 50%;
  max-width: 4.8rem;
  max-height: 4.8rem;
  fill: var(--clr-default-100);
  transition: fill var(--transition), background-color var(--transition);
}

.footer__icon a svg:hover {
  fill: var(--clr-primary-100);
  background-color: var(--clr-default-100);
  transition: fill var(--transition), background-color var(--transition);
}

.footer__policy {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.footer__policy a {
  color: var(--clr-default-100);
}

.footer__policy p {
  color: rgba(255, 255, 255, 0.66);
}

.footer__info {
  margin-right: 1.6rem;
}

.footer__info a {
  transition: color var(--transition);
}

.footer__info a:hover {
  color: var(--clr-primary-100);
  transition: color var(--transition);
}

.footer__info a:not(:last-child) {
  margin-right: 1.6rem;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.66), rgba(0, 0, 0, 0.66));
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.modal__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  min-height: 100%;
}

.modal .join__wrapper {
  overflow: hidden;
  border-radius: 2rem;
}

.modal__content {
  position: relative;
  border-radius: 2rem;
  padding: 2rem;
  width: 100%;
  max-width: 60rem;
  background-color: var(--clr-default-100);
  opacity: 0;
  transform: translateY(-100%);
  transition: transform var(--transition), opacity var(--transition);
}

.modal .modal__register-content {
  display: flex;
  padding: 0;
}

.modal__close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}

.modal__close::after,
.modal__close::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 0.2rem;
  background-color: var(--clr-default-900);
}

.modal__close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.modal__close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.modal .modal__register-close {
  right: 1.5rem;
}

.modal.show {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

.modal.show .modal__content {
  opacity: 1;
  transform: translateY(0);
  transition: transform var(--transition), opacity var(--transition);
}

.modal__form {
  border-radius: 2rem;
  padding: 5rem;
  background-color: var(--clr-default-100);
}

.modal__form-status {
  display: none;
}

.modal__form-status--success,
.modal__form-status--valid,
.modal__form-status--error {
  margin-top: 1.5rem;
}

.modal__title {
  padding-bottom: 1.6rem;
  font-size: clamp(
    2.4rem,
    2.0444444444rem + 1.1111111111vw,
    3.4rem
  );
}

.modal .join__title {
  padding-right: 4rem;
}

.modal__subtitle {
  padding-bottom: 3rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  color: var(--clr-default-200);
}

.modal__input {
  border-radius: 0.8rem;
  padding: 1.6rem;
  width: 100%;
  background-color: #f8f8f8;
}

.modal__input:focus {
  outline: 0.2rem solid var(--clr-primary-100);
}

.modal__input-wrapper + .modal__input-wrapper {
  margin-top: 2.5rem;
}

.modal__policy {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.modal__policy .contact__option {
  margin-bottom: 0;
}

.modal__submit {
  margin-top: 2rem;
  min-width: 100%;
}

.just-validate-error-label {
  margin-top: 0.3rem;
  font-size: 1.4rem;
}

.thanks__content {
  padding: 3rem;
  width: 100%;
  max-width: 60rem;
  background-color: var(--clr-default-100);
}

.thanks .modal__content {
  background-color: var(--clr-default-100);
}

.thanks__title {
  padding-bottom: 1.5rem;
  font-size: clamp(
    2.4rem,
    2.0444444444rem + 1.1111111111vw,
    3.4rem
  );
  text-align: center;
}

.thanks__subtitle {
  line-height: 150%;
}

.thanks__subtitle:not(:last-child) {
  margin-bottom: 2rem;
}

.thanks .modal__close::after,
.thanks .modal__close::before {
  content: "";
  background-color: var(--clr-default-900);
}

.cookies {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  border-radius: 24px 24px 0px 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: 1px -24px 22px rgba(41, 41, 41, 0.14);
  background: var(--clr-default-100);
}

.cookies--hide {
  transform: translateY(100%);
  transition: transform var(--transition);
}

.cookies__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cookies__inner {
  display: flex;
}

.cookies__img {
  margin-right: 1.5rem;
  max-width: 5rem;
}

.cookies__title {
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: clamp(
    1.8rem,
    1.6577777778rem + 0.4444444444vw,
    2.2rem
  );
  color: #1c0b24;
}

.cookies__text {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  letter-spacing: -0.3px;
  color: rgba(60, 60, 67, 0.85);
}

.cookies__text a {
  color: #c10fc1;
}

.cookies__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
  border-radius: 0.8rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  min-width: 29rem;
  min-height: 5rem;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 145%;
  text-align: center;
  color: var(--clr-default-100);
  background-color: #c10fc1;
  transition: background-color var(--transition);
}

.hero {
  position: relative;
  overflow: hidden;
  height: 110vh;
  min-height: 115rem;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-400);
}

.hero::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -13rem;
  z-index: 3;
  border-radius: 5.2rem 5.2rem 0px 0px;
  width: 100%;
  height: 20rem;
  box-shadow: 0px -20px 35px rgba(0, 0, 0, 0.04);
  background-color: var(--clr-default-100);
  pointer-events: none;
}

.hero__particles {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.hero__particles > * {
  width: 100% !important;
  height: 100% !important;
}

.hero__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero__text {
  z-index: 1;
  margin-bottom: 13.7rem;
  max-width: 102rem;
}

.hero__title {
  margin-bottom: 2.4rem;
  padding-top: 10rem;
  font-size: clamp(
    3.1rem,
    1.7133333333rem + 4.3333333333vw,
    7rem
  );
  text-align: center;
}

.hero__sibtitle {
  margin-bottom: 4.8rem;
  font-size: clamp(
    1.6rem,
    1.4577777778rem + 0.4444444444vw,
    2rem
  );
  line-height: 150%;
  text-align: center;
}

.hero__button {
  margin: 0 auto;
}

.hero__img {
  position: absolute;
  left: 0;
  bottom: 4.4rem;
  z-index: 1;
  display: flex;
  width: 100%;
}

.hero__img img {
  margin-right: auto;
  margin-left: auto;
}

.hero__lottie {
  position: absolute;
  left: calc((100% - var(--container-width) - 17rem) / 2);
  bottom: 22.4rem;
  z-index: 2;
  width: 100%;
  max-width: 50rem;
  opacity: 0;
  filter: drop-shadow(0px 21.9523px 26.3428px rgba(0, 0, 0, 0.102));
  transform: rotate(-10deg);
  transition: opacity 0.4s;
}

.statistics {
  overflow: hidden;
}

.statistics__wrapper {
  position: relative;
  padding-bottom: 47rem;
}

.statistics__content {
  max-width: 60rem;
}

.statistics__title {
  padding-bottom: 2.4rem;
}

.statistics__title span {
  font-weight: 600;
  color: var(--clr-primary-200);
}

.statistics__text {
  line-height: 150%;
}

.statistics__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 28.8rem;
  height: 28.8rem;
  box-shadow: 0px -8px 35px rgba(0, 0, 0, 0.03), 1px 26px 35px rgba(66, 66, 66, 0.08);
  background-color: var(--clr-default-100);
  opacity: 0;
  transform: translateY(5rem);
  transition: opacity var(--transition), transform var(--transition);
}

.statistics__item--first,
.statistics__item--two,
.statistics__item--three {
  position: absolute;
}

.statistics__item--first {
  left: 20rem;
  bottom: 2rem;
}

.statistics__item--two {
  left: 62rem;
  bottom: 16rem;
}

.statistics__item--three {
  right: 0;
  top: 0;
}

.statistics__item-title {
  padding-bottom: 4.4rem;
  font-size: clamp(
    1.8rem,
    1.5155555556rem + 0.8888888889vw,
    2.6rem
  );
  text-align: center;
}

.statistics__item-title span {
  font-size: clamp(
    2.8rem,
    2.0888888889rem + 2.2222222222vw,
    4.8rem
  );
  color: var(--clr-primary-200);
}

.statistics__item-subtitle {
  font-weight: 700;
  font-size: 2rem;
  color: var(--clr-default-200);
}

.statistics__line-desctop {
  position: absolute;
  left: -15rem;
  bottom: 8.5rem;
  z-index: -1;
  width: 144rem;
  height: 54rem;
}

.statistics__line-desctop .path {
  stroke-dasharray: 2200;
  stroke-dashoffset: 4400;
  animation-name: stroke-desctop;
  animation-duration: 2s;
  animation-play-state: paused;
}

.statistics__line-desctop .circle {
  opacity: 0;
  transition: opacity var(--transition);
}

.statistics__line-mobile {
  position: absolute;
  left: 25%;
  top: 3%;
  display: none;
}

.statistics__line-mobile .path {
  stroke-dasharray: 1100;
  stroke-dashoffset: 0;
  animation: stroke 10s;
  animation-name: stroke-mobile;
  animation-duration: 2s;
  animation-play-state: paused;
}

.statistics__line-mobile .circle {
  opacity: 0;
  transition: opacity var(--transition);
}

@keyframes stroke-desctop {
  0% {
    stroke-dashoffset: 2200;
  }

  100% {
    stroke-dashoffset: 4400;
  }
}

@keyframes stroke-mobile {
  0% {
    stroke-dashoffset: 1100;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.for {
  height: 100vh;
}

.for__title {
  padding-bottom: 8rem;
}

.for__text {
  line-height: 150%;
  text-align: start;
  color: var(--clr-default-200);
}

.for__text p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.for__items {
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 6rem;
}

.for__item {
  position: relative;
  max-width: 39rem;
  text-align: center;
}

.for__item-image {
  margin-bottom: 2.5rem;
}

.for__items-title {
  padding-bottom: 1.6rem;
  font-size: 3.6rem;
}

.for__items-subtitle {
  line-height: 150%;
  color: var(--clr-default-200);
}

.for__button {
  margin: 0 auto;
}

.solution__title {
  padding-bottom: 8rem;
}

.solution__item {
  height: 90vh;
}

.solution__item:not(:last-child) {
  margin-bottom: 7rem;
}

.solution__item-inner {
  position: sticky;
  top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.solution__item-inner > * {
  flex: 0 1 49%;
}

.solution__content-title {
  padding-bottom: 1.6rem;
  font-size: clamp(
    2.8rem,
    2.5155555556rem + 0.8888888889vw,
    3.6rem
  );
}

.solution__content-subtitle {
  padding-bottom: 4.8rem;
  line-height: 120%;
  color: var(--clr-primary-500);
}

.solution__content-text {
  line-height: 150%;
}

.solution__img {
  max-width: 70rem;
}

.solution__img img {
  border-radius: 2.4rem;
}

.testimonials {
  position: relative;
  height: 130vh;
}

.testimonials__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8rem;
}

.testimonials__title-wrap {
  margin-right: 1.5rem;
}

.testimonials__title {
  margin-bottom: 1.5rem;
}

.testimonials__subtitle {
  color: var(--clr-default-200);
}

.testimonials__navigation {
  display: flex;
  align-items: center;
  align-self: end;
  justify-content: space-between;
  min-width: 17rem;
}

.testimonials__pagination {
  font-size: 1.8rem;
}

.testimonials__button-prev,
.testimonials .testimonials__button-next {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  background-image: url(../img/testimonials/arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--clr-primary-100);
  transition: background-color var(--transition), opacity var(--transition);
}

.testimonials__button-prev:hover,
.testimonials .testimonials__button-next:hover {
  background-color: var(--clr-primary-400);
  transition: background-color var(--transition);
}

.testimonials .swiper-button-disabled {
  opacity: 0.3;
  transition: opacity var(--transition);
  pointer-events: none;
}

.testimonials__button-prev {
  transform: rotate(-180deg);
}

.testimonials__bottom {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.testimonials__slider {
  padding-left: calc((100% - 117rem) / 2);
}

.testimonials__slide {
  border: 2px solid var(--clr-default-200);
  border-radius: 2.4rem;
  padding: 4rem;
  height: auto;
}

.testimonials__info {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
}

.testimonials__img {
  max-width: 5.6rem;
}

.testimonials__img img {
  border-radius: 0.4rem;
}

.testimonials__person {
  margin-left: 2rem;
}

.testimonials__name {
  margin-bottom: 0.4rem;
  font-weight: 700;
  font-size: 2rem;
}

.testimonials__spec {
  color: var(--clr-default-200);
}

.testimonials__review p {
  line-height: 150%;
}

.testimonials__review p:not(:last-child) {
  margin-bottom: 2rem;
}

.start {
  position: relative;
  overflow: hidden;
  margin-top: 20rem;
  background-color: var(--clr-primary-400);
}

.start__wrapper {
  position: relative;
  z-index: 1;
  padding-bottom: 51rem;
}

.start__wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/start/bg.svg);
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

.start__title {
  padding-top: 10rem;
  padding-bottom: 4rem;
  max-width: 43rem;
  color: var(--clr-default-100);
}

.start__button {
  position: relative;
  z-index: 1;
}

.start__item {
  position: absolute;
  max-width: 22rem;
  font-size: 2rem;
  color: var(--clr-default-100);
}

.start__item:first-child {
  left: 0;
  top: 52.5rem;
}

.start__item:nth-child(2) {
  left: 23.2rem;
  top: 46.5rem;
}

.start__item:nth-child(3) {
  left: 49.2rem;
  top: 43.5rem;
}

.start__item:nth-child(4) {
  left: 73.2rem;
  top: 38.5rem;
}

.start__item:last-child {
  left: 96.2rem;
  top: 34.5rem;
}

.start__item:last-child::before {
  content: "";
  position: absolute;
  right: -80px;
  top: -300px;
  width: 39rem;
  height: 39rem;
  background-image: url(../img/start/prise.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.start__particles {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.partners {
  height: 80vh;
}

.partners__title {
  padding-bottom: 6rem;
}

.partners__items {
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
}

.partners__item {
  min-height: 15rem;
  box-shadow: 1px 1px 25px rgba(70, 70, 70, 0.06);
}

.partners__item:hover img {
  opacity: initial;
  transition: opacity var(--transition);
}

.partners__item a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.partners__item img {
  opacity: 0.55;
  transition: opacity var(--transition);
}

.demo {
  overflow: hidden;
  margin-top: 20rem;
  height: 90vh;
}

.demo__wrapper {
  position: relative;
  border-radius: 2.4rem;
  padding-top: 7rem;
  padding-right: 3rem;
  padding-bottom: 7rem;
  padding-left: 10rem;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-400);
}

.demo__wrapper::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 52rem;
  min-height: 48rem;
  background-image: url(../img/demo/bg.svg);
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

.demo__title {
  padding-bottom: 1.5rem;
  max-width: 60rem;
  font-size: 6rem;
}

.demo__subtitle {
  padding-bottom: 5rem;
  max-width: 60rem;
  font-weight: 400;
  line-height: 150%;
}

.seen {
  margin-bottom: 20rem;
  height: 80vh;
}

.seen__title {
  padding-bottom: 6rem;
}

.seen__items {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(3, 1fr);
}

.seen__item {
  display: flex;
  border-radius: 1.2rem;
  min-height: 15rem;
  max-width: 39rem;
  box-shadow: 1px 1px 25px rgba(70, 70, 70, 0.06);
}

.seen__item:hover img {
  opacity: initial;
  transition: opacity var(--transition);
}

.seen__item a {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
}

.seen__item img {
  opacity: 0.55;
  transition: opacity var(--transition);
}

.faq {
  margin-bottom: 20rem;
}

.faq__title {
  padding-bottom: 8rem;
}

.faq__body {
  margin-bottom: 8rem;
}

.faq__wrapper:not(:last-child) {
  margin-bottom: 2rem;
}

.faq__wrapper.active .faq__question::after {
  transform: translateY(-50%) rotate(-45deg);
  transition: transform var(--transition);
}

.faq__wrapper.active .faq__answer {
  transition: max-height var(--transition), padding var(--transition);
}

.faq__question {
  position: relative;
  padding-top: 2rem;
  padding-right: 4rem;
  padding-bottom: 2rem;
  width: 100%;
  font-weight: 600;
  font-size: clamp(
    2rem,
    1.7866666667rem + 0.6666666667vw,
    2.6rem
  );
}

.faq__question::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 2.3rem;
  height: 2.3rem;
  background-image: url(../img/faq/plus.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%);
  transition: transform var(--transition);
}

.faq__answer {
  overflow: hidden;
  max-height: 0;
  transition: max-height var(--transition);
}

.faq__answer p {
  line-height: 150%;
}

.faq__answer p:not(:last-child) {
  margin-bottom: 2rem;
}

.faq__answer ul {
  list-style: disc;
  padding-left: 2rem;
}

.faq__answer ul li {
  line-height: 150%;
}

.faq__answer ul li span {
  font-weight: 600;
}

.faq__answer ul li:not(:last-child) {
  margin-bottom: 1rem;
}

.faq__list-mb {
  margin-bottom: 2rem;
}

.blog {
  margin-bottom: 20rem;
}

.blog__title {
  padding-bottom: 8rem;
}

.blog__items {
  display: grid;
  grid-gap: 5rem 2.3rem;
  grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
}

.blog__img {
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
  padding-bottom: 67%;
  width: 100%;
  max-width: 39rem;
}

.blog__img img {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 1.6rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.blog__date {
  padding-bottom: 1rem;
  font-size: 1.2rem;
  color: var(--clr-default-200);
}

.blog__item-title {
  padding-bottom: 1rem;
  font-size: clamp(
    1.8rem,
    1.5155555556rem + 0.8888888889vw,
    2.6rem
  );
  color: var(--clr-default-300);
}

.blog__item-text {
  line-height: 150%;
  color: var(--clr-default-300);
}

.blog__pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7rem;
}

.blog__pagination-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid #c4c4c4;
  border-radius: 0.4rem;
  min-width: 4rem;
  min-height: 4rem;
  transition: background-color var(--transition);
}

.blog__pagination-btn svg {
  width: 1.6rem;
  height: 1.4rem;
  fill: var(--clr-default-300);
  transition: fill var(--transition);
}

.blog__pagination-btn--right svg {
  transform: rotate(-180deg);
}

.blog__pagination-btn--disabled {
  pointer-events: none;
}

.blog__pagination-btn--disabled svg {
  fill: #c4c4c4;
}

.blog__pagination-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 2.6rem;
  margin-left: 2.6rem;
}

.blog__pagination-item {
  height: 4.3rem;
  min-width: 2.8rem;
}

.blog__pagination-item:not(:last-child) {
  margin-right: 1.2rem;
}

.blog__pagination-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 600;
  color: var(--clr-default-300);
}

.blog__pagination-item--active {
  border-bottom: 0.1rem solid var(--clr-primary-100);
}

.blog__pagination-item--active a {
  color: var(--clr-primary-100);
}

.blog__form-title {
  padding-bottom: 3rem;
  font-size: clamp(
    2.4rem,
    1.9733333333rem + 1.3333333333vw,
    3.6rem
  );
  line-height: 125%;
}

.blog__form-inner {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.blog__form-warning {
  letter-spacing: -0.4px;
  color: var(--clr-default-400);
  opacity: 0.5;
}

.blog__form-input {
  border-radius: 0.8rem;
  padding: 1.4rem 2rem 1.4rem 1.6rem;
  min-width: 100%;
  background-color: #eff2f6;
}

.blog__form-input-wrapper {
  margin-bottom: 1.6rem;
}

.blog__form-input::placeholder {
  color: #c4c4c4;
}

.blog__form-btn {
  display: flex;
  justify-content: center;
  border-radius: 0.8rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  min-width: 100%;
  font-weight: 600;
  font-size: 1.8rem;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-100);
  transition: background-color var(--transition);
  cursor: pointer;
}

.blog__form-btn:hover {
  background-color: #e200e2;
  transition: background-color var(--transition);
}

.blog__button {
  margin: 0 auto;
}

.article__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.article__back {
  position: relative;
  display: block;
  align-self: start;
  margin-top: 8rem;
  margin-bottom: 4rem;
  padding-left: 2.5rem;
  color: var(--clr-default-300);
}

.article__back::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1.2rem;
  height: 1.8rem;
  background-image: url(../img/article/back.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}

.article__icons {
  display: flex;
  flex-direction: column;
}

.article__icons li a svg {
  fill: #000;
}

.article__icons li:not(:last-child) {
  margin-bottom: 2rem;
}

.article__content {
  margin: 0 auto;
  padding-bottom: 20rem;
  max-width: 80rem;
}

.article__date {
  padding-bottom: 2.5rem;
  letter-spacing: -0.3px;
  color: var(--clr-default-200);
}

.article__title {
  padding-bottom: 3rem;
  line-height: 111%;
}

.article__subtitle {
  padding-bottom: 4.5rem;
  font-size: 2rem;
  letter-spacing: -0.4px;
  color: var(--clr-default-500);
}

.article__img {
  padding-bottom: 5rem;
  max-width: 80rem;
}

.article__img img {
  border-radius: 0.8rem;
}

.article__description {
  padding-bottom: 5rem;
  line-height: 150%;
  letter-spacing: -0.3px;
  color: var(--clr-default-400);
}

.article__header {
  padding-bottom: 1.6rem;
  font-weight: 700;
  font-size: 2rem;
  color: var(--clr-default-500);
}

.article__header-description {
  padding-bottom: 1.6rem;
  letter-spacing: -0.3px;
  letter-spacing: -0.3px;
  color: var(--clr-default-400);
}

.article__list {
  list-style: disc;
  margin-bottom: 5rem;
  margin-left: 1.8rem;
  color: var(--clr-default-400);
}

.article__list li:not(:last-child) {
  margin-bottom: 2rem;
}

.article__list li span {
  color: var(--clr-primary-200);
}

.article__bottom {
  color: var(--clr-default-400);
}

.article__bottom p:not(:last-child) {
  margin-bottom: 2rem;
}

.price__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8rem;
}

.price__text {
  margin-right: 2rem;
}

.price__image {
  max-width: 33rem;
}

.price__title {
  padding-bottom: 2.4rem;
}

.price__subtitle {
  max-width: 63rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  color: var(--clr-default-900);
}

.price__item {
  align-self: start;
  border: 0.1rem solid #c4c4c4;
  border-radius: 1.2rem;
  padding: 2rem;
}

.price__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid #c4c4c4;
  padding-bottom: 2rem;
}

.price__top-title {
  font-weight: 700;
  font-size: 1.8rem;
}

.price__top-subtitle {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: var(--clr-default-200);
}

.price__value {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: clamp(
    1.8rem,
    1.5511111111rem + 0.7777777778vw,
    2.5rem
  );
}

.price__value span {
  display: inline-block;
  align-self: flex-end;
  margin-left: 0.5rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 145%;
  color: rgba(60, 60, 67, 0.85);
}

.price__button {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 0.1rem solid var(--clr-primary-100);
  border-radius: 1.2rem;
  padding-right: 2rem;
  padding-left: 2rem;
  width: 100%;
  min-height: 6.4rem;
  box-shadow: initial;
  font-weight: 600;
  font-size: 1.8rem;
  color: var(--clr-default-300);
  background-color: initial;
  transition: background-color var(--transition), color var(--transition);
}

.price__button:hover {
  color: var(--clr-default-100);
  transition: background-color var(--transition), color var(--transition);
}

.price__list {
  font-weight: 600;
  line-height: 145%;
  color: var(--clr-default-300);
}

.price__list-title {
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.price__list li {
  position: relative;
  padding-left: 1.3rem;
  font-size: clamp(
    1.4rem,
    1.3288888889rem + 0.2222222222vw,
    1.6rem
  );
}

.price__list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.7rem;
  border-radius: 50%;
  width: 0.4rem;
  height: 0.4rem;
  background-color: var(--clr-default-300);
}

.price__list li:not(:last-child) {
  margin-bottom: 1.5rem;
}

.price__service {
  position: relative;
  padding: 5rem 2.5rem;
}

.price__service::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 0.1rem;
  background-color: #c4c4c4;
}

.price__section--active {
  max-width: 100%;
}

.price__section--active .price__content {
  max-height: 100%;
}

.price__section--active .price__content::before {
  display: none;
}

.price__section--active .price__control svg {
  transform: rotate(180deg);
  transition: transform var(--transition);
}

.price .price__service--overview::after,
.price .price__service--features::after,
.price .price__service--limits::after,
.price .price__service--upgrade::after,
.price .price__service--support::after {
  position: absolute;
  left: 50%;
  top: -1.6rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-weight: 600;
  font-size: clamp(
    2rem,
    1.7866666667rem + 0.6666666667vw,
    2.6rem
  );
  line-height: 120%;
  white-space: nowrap;
  color: #1c0b24;
  background-color: var(--clr-default-100);
  transform: translateX(-50%);
}

.price .price__service--overview::after {
  content: "Overview";
}

.price .price__service--features::after {
  content: "Key features";
}

.price .price__service--limits::after {
  content: "Limits";
}

.price .price__service--upgrade::after {
  content: "Upgrade packages";
}

.price .price__service--support::after {
  content: "Support";
}

.price__content {
  position: relative;
  overflow: hidden;
  border: 0.1rem solid #c4c4c4;
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  max-height: 74rem;
}

.price__content::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  height: 6rem;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 25.6%, #ffffff 100%);
}

.price__slide {
  height: auto;
}

.price__slide-header {
  padding: 3rem 2.5rem 5rem 2.5rem;
}

.price__slide--2 {
  border-right: 0.1rem solid #c4c4c4;
  border-left: 0.1rem solid #c4c4c4;
}

.price__slide--mobile.active .price__slide-wrap {
  max-height: 100%;
}

.price__slide--mobile.active .price__slide-wrap::before {
  display: none;
}

.price__slide--mobile.active .price__control--mobile svg {
  transform: rotate(180deg);
  transition: transform var(--transition);
}

.price__slide-wrap {
  position: relative;
  overflow: hidden;
  border: 0.1rem solid #c4c4c4;
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  max-height: 54rem;
}

.price__slide-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  height: 6rem;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 25.6%, #ffffff 100%);
}

.price__slide--mobile + .price__slide--mobile {
  margin-top: 2rem;
}

.price__control,
.price__control--mobile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0.1rem solid #c4c4c4;
  border-bottom: 0.1rem solid #c4c4c4;
  border-left: 0.1rem solid #c4c4c4;
  border-bottom-right-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
  padding: 3rem;
  width: 100%;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 145%;
  letter-spacing: -0.4px;
  color: var(--clr-primary-100);
  transition: background-color var(--transition);
}

.price__control svg,
.price__control--mobile svg {
  margin-left: 1.5rem;
  transition: transform var(--transition);
}

.price__footer {
  margin-top: 6rem;
  margin-bottom: 4rem;
}

.fee {
  padding-bottom: 20rem;
}

.fee__title {
  padding-bottom: 2.5rem;
}

.fee__subtitle {
  padding-bottom: 8rem;
  max-width: 60rem;
  font-weight: 400;
  line-height: 150%;
}

.fee__list {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  padding-bottom: 6rem;
}

.fee__price {
  display: flex;
  justify-content: center;
  padding-bottom: 1.6rem;
}

.fee__price span {
  font-weight: 600;
  font-size: 3.6rem;
  color: var(--clr-primary-100);
}

.fee__price p {
  align-self: flex-end;
}

.fee__info {
  text-align: center;
  color: var(--clr-default-200);
}

.fee__content {
  padding-bottom: 7rem;
  max-width: 60rem;
}

.fee__text {
  line-height: 150%;
  color: var(--clr-default-900);
}

.fee__text + .fee__text {
  margin-top: 2.5rem;
}

.about__title {
  padding-bottom: 8rem;
}

.about__content {
  display: flex;
  align-items: center;
}

.about__text {
  margin-right: 2.5rem;
  max-width: 80rem;
}

.about__text p {
  line-height: 150%;
}

.about__text p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.about__img {
  max-width: 39rem;
}

.our__body {
  display: flex;
  align-items: center;
}

.our__body:first-child {
  margin-bottom: 10rem;
}

.our__body:last-child {
  flex-direction: row-reverse;
}

.our__body:last-child .our__image {
  margin-right: initial;
  margin-left: 5rem;
}

.our__image {
  flex-shrink: 0;
  margin-right: 5rem;
  max-width: 30rem;
}

.our__body-title {
  margin-bottom: 1.6rem;
  font-weight: 600;
  font-size: clamp(
    2.4rem,
    1.9733333333rem + 1.3333333333vw,
    3.6rem
  );
  line-height: 135%;
  letter-spacing: -2px;
}

.our__text p {
  line-height: 150%;
  letter-spacing: -0.3px;
}

.our__text p:not(:last-child) {
  margin-bottom: 2rem;
}

.our__list {
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: 1fr 1fr;
  margin-top: 3rem;
}

.our__item {
  position: relative;
  padding-left: 4rem;
  font-weight: 600;
}

.our__item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(../img/our/done.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.ceo {
  margin-top: 20rem;
  background-color: var(--clr-primary-200);
}

.ceo__wrapper {
  position: relative;
  display: flex;
  align-content: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
  color: var(--clr-default-100);
}

.ceo__wrapper::before,
.ceo__wrapper::after {
  content: "";
  position: absolute;
  width: 8rem;
  height: 8rem;
  background-image: url(../img/ceo/quotes.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.ceo__wrapper::before {
  left: 0;
  top: -4rem;
}

.ceo__wrapper::after {
  right: 26rem;
  bottom: -4rem;
  transform: scale(-1, 1);
}

.ceo__img {
  margin-right: 4rem;
  max-width: 23rem;
}

.ceo__text {
  max-width: 62rem;
}

.ceo__title {
  padding-bottom: 2.4rem;
  font-size: clamp(
    2.8rem,
    2.5155555556rem + 0.8888888889vw,
    3.6rem
  );
}

.ceo__info {
  padding-bottom: 1rem;
  line-height: 150%;
  letter-spacing: -0.3px;
}

.ceo__info span {
  font-weight: 600;
  font-size: 2.5rem;
  color: var(--clr-primary-500);
}

.ceo__name {
  padding-right: 2rem;
  font-weight: 600;
  text-align: right;
}

.contact {
  padding-bottom: 20rem;
}

.contact__title {
  padding-bottom: 8rem;
}

.contact__wrapper {
  display: flex;
}

.contact__thanks {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2.4rem;
  padding: 6rem 5rem;
  max-width: 60rem;
  box-shadow: 0px -8px 35px rgba(0, 0, 0, 0.03), 1px 26px 35px rgba(66, 66, 66, 0.08);
  background-color: var(--clr-default-100);
}

.contact__thanks .thanks__subtitle {
  text-align: center;
}

.contact__form {
  border-radius: 2.4rem;
  padding: 6rem 5rem;
  max-width: 60rem;
  box-shadow: 0px -8px 35px rgba(0, 0, 0, 0.03), 1px 26px 35px rgba(66, 66, 66, 0.08);
  color: var(--clr-default-300);
  background-color: var(--clr-default-100);
}

.contact__form-input + .contact__form-input {
  margin-top: 2.5rem;
}

.contact__form-title {
  margin-bottom: 3rem;
  padding-bottom: 1.6rem;
  font-weight: 600;
  font-size: clamp(
    2.6rem,
    2.2444444444rem + 1.1111111111vw,
    3.6rem
  );
}

.contact__name {
  border-radius: 0.8rem;
  padding: 1.6rem;
  min-width: 100%;
  background-color: #f8f8f8;
}

.contact__name:focus {
  outline: 0.2rem solid var(--clr-primary-100);
}

.contact__mail {
  border-radius: 0.8rem;
  padding: 1.6rem;
  min-width: 100%;
  background-color: #f8f8f8;
}

.contact__mail:focus {
  outline: 0.2rem solid var(--clr-primary-100);
}

.contact__textarea {
  border-radius: 0.8rem;
  padding: 1.6rem;
  min-width: 100%;
  min-height: 15rem;
  background-color: #f8f8f8;
  resize: none;
}

.contact__textarea:focus {
  outline: 0.2rem solid var(--clr-primary-100);
}

.contact__option {
  position: relative;
  display: flex;
  align-items: center;
}

.contact__input {
  position: absolute;
  left: 2rem;
  width: 1px;
  height: 1px;
}

.contact__box {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  border: 1px solid var(--clr-primary-100);
  border-radius: 0.4rem;
  width: 2.8rem;
  height: 2.8rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: var(--clr-default-100);
}

.contact__input:checked + .contact__box {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.81818L4.125 8L11 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
  background-color: var(--clr-primary-100);
}

.contact__policy {
  margin-top: 2.5rem;
  margin-bottom: 5rem;
}

.contact__policy-text {
  position: relative;
  padding-left: 4rem;
  min-height: 3rem;
  font-size: clamp(
    1.4rem,
    1.3288888889rem + 0.2222222222vw,
    1.6rem
  );
}

.contact__policy-text a {
  text-decoration: underline;
  color: var(--clr-primary-500);
}

.contact__submit {
  min-width: 100%;
}

.contact__info {
  margin-top: 6rem;
  margin-left: 12rem;
}

.contact__phone {
  padding-bottom: 5rem;
}

.contact__phone p {
  padding-bottom: 1.2rem;
  color: var(--clr-default-200);
}

.contact__phone a {
  font-weight: 600;
  font-size: clamp(
    1.8rem,
    1.5155555556rem + 0.8888888889vw,
    2.6rem
  );
  color: var(--clr-default-300);
}

.contact__mail-left {
  padding-bottom: 5rem;
}

.contact__mail-left p {
  padding-bottom: 1.2rem;
  color: var(--clr-default-200);
}

.contact__mail-left a {
  font-weight: 600;
  font-size: clamp(
    1.8rem,
    1.5155555556rem + 0.8888888889vw,
    2.6rem
  );
  color: var(--clr-default-300);
}

.contact__social p {
  padding-bottom: 1.2rem;
  color: var(--clr-default-200);
}

.contact__icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  min-height: 13rem;
  max-width: 24rem;
}

.contact__icons li a svg {
  fill: var(--clr-default-300);
}

.contact__icons li:not(:last-child) {
  margin-right: 3rem;
}

.contact .footer__icons {
  margin: 0;
}

.capabilities__title {
  padding-bottom: 8rem;
}

.capabilities__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.capabilities__wrapper:not(:last-child) {
  margin-bottom: 5rem;
}

.capabilities__wrapper--reverse {
  flex-direction: row-reverse;
}

.capabilities__wrapper--reverse .capabilities__info {
  margin-left: initial;
}

.capabilities__wrapper--reverse .capabilities__info::before {
  left: initial;
  right: -2.4rem;
  transform: scale(-1, 1);
}

.capabilities__wrapper--reverse .capabilities__image {
  margin-right: initial;
  margin-left: 5rem;
}

.capabilities__wrapper--reverse .capabilities__info-title {
  margin-bottom: 1.2rem;
}

.capabilities__image {
  margin-right: 5rem;
  max-width: 22rem;
}

.capabilities__info {
  position: relative;
  border: solid 0.2rem #D9D9D9;
  border-radius: 2.4rem;
  padding-top: 3rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
  padding-left: 2rem;
  min-width: 87rem;
  background-color: #F2F2F2;
}

.capabilities__info::before {
  content: "";
  position: absolute;
  left: -2.4rem;
  top: 11.7rem;
  width: 2.4rem;
  height: 4.5rem;
  background-image: url(../img/capabilities/decor.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.capabilities__info-title {
  margin-bottom: 3rem;
  max-width: 70rem;
  font-weight: 600;
  font-size: clamp(
    2rem,
    1.4311111111rem + 1.7777777778vw,
    3.6rem
  );
  line-height: 135%;
  letter-spacing: -2px;
}

.capabilities__info-text {
  max-width: 77rem;
}

.capabilities__info-text p {
  font-weight: 500;
  font-size: clamp(
    1.8rem,
    1.7288888889rem + 0.2222222222vw,
    2rem
  );
  line-height: 120%;
}

.capabilities__info-text p:not(:last-child) {
  margin-bottom: 3rem;
}

.capabilities__info-list {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
  max-width: 81rem;
}

.capabilities__info-item {
  position: relative;
  padding-left: 4rem;
  font-weight: 600;
  font-size: clamp(
    1.6rem,
    1.4577777778rem + 0.4444444444vw,
    2rem
  );
}

.capabilities__info-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(../img/capabilities/item-decor.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.capabilities__text {
  margin-bottom: 10rem;
  max-width: 80rem;
  font-weight: 500;
  font-size: clamp(
    1.8rem,
    1.7288888889rem + 0.2222222222vw,
    2rem
  );
  line-height: 120%;
}

.capabilities__text p:not(:last-child) {
  margin-bottom: 3rem;
}

.analyse {
  margin-bottom: 20rem;
}

.analyse__tabs-nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6rem;
  border-bottom: 0.4rem solid #c4c4c4;
}

.analyse__tabs-btn {
  position: relative;
  padding-bottom: 2.5rem;
  font-weight: 600;
  font-size: clamp(
    1.6rem,
    1.4577777778rem + 0.4444444444vw,
    2rem
  );
  line-height: 120%;
  color: var(--clr-default-200);
}

.analyse__tabs-btn::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.4rem;
  width: 0;
  height: 0.4rem;
  background-color: var(--clr-primary-100);
}

.analyse__tabs-btn:not(:last-child) {
  margin-right: 5rem;
}

.analyse__tabs-btn.active {
  color: var(--clr-primary-100);
}

.analyse__tabs-btn.active::before {
  width: 100%;
  transition: width var(--transition);
}

.analyse__wrapper {
  display: flex;
  align-items: center;
}

.analyse__wrapper--middle {
  flex-direction: row-reverse;
}

.analyse__content {
  margin-right: 3rem;
  max-width: 70rem;
}

.analyse__content--middle {
  margin-right: initial;
  margin-left: 3rem;
}

.analyse__title {
  padding-bottom: 6rem;
  font-size: clamp(
    2.6rem,
    2.2444444444rem + 1.1111111111vw,
    3.6rem
  );
  color: var(--clr-primary-200);
}

.analyse__text:not(:last-child) {
  margin-bottom: 5rem;
}

.analyse__text-title {
  padding-bottom: 2.5rem;
  font-size: clamp(
    1.8rem,
    1.5155555556rem + 0.8888888889vw,
    2.6rem
  );
}

.analyse__text-subtitle {
  line-height: 150%;
  color: var(--clr-default-200);
}

.analyse__img {
  max-width: 49rem;
}

.analyse__img img {
  border-radius: 2.4rem;
}

.analyse .hide {
  height: 0;
  visibility: hidden;
}

.analyse__list li {
  position: relative;
  padding-left: 1.5rem;
  font-size: 1.6rem;
  line-height: 150%;
  letter-spacing: -0.3px;
  color: rgba(60, 60, 67, 0.85);
}

.analyse__list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 1rem;
  border-radius: 50%;
  width: 0.4rem;
  height: 0.4rem;
  background-color: rgba(60, 60, 67, 0.85);
}

.analyse__list li + li {
  margin-top: 1.5rem;
}

@media (min-width: 36.0625em) {
  .price__section--mobile {
    display: none;
  }
}

@media (min-width: 62.0625em) {
  .for .container {
    position: sticky;
    top: 4rem;
  }

  .solution__item:nth-child(2) .solution__item-inner {
    flex-direction: row-reverse;
  }

  .testimonials__wrapper {
    position: sticky;
    top: 4rem;
  }

  .partners .container {
    position: sticky;
    top: 4rem;
  }

  .seen .container {
    position: sticky;
    top: 4rem;
  }
}

@media (min-width: 36em) {
  .demo .container {
    position: sticky;
    top: 4rem;
  }
}

@media (max-width: 75em) {
  .go-up {
    right: 3.5rem;
    bottom: 8.5rem;
  }

  .menu__list {
    flex-direction: column;
    align-items: initial;
    margin-top: 10rem;
    margin-left: 2.5rem;
    width: 100%;
    padding-block: 4rem;
  }

  .menu__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 2.5rem;
  }

  .menu__item a {
    font-weight: 600;
    font-size: 2.8rem;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-300);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    transition: right var(--transition);
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: var(--clr-default-100);
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .header__buttons {
    margin-right: 2rem;
  }

  .modal__form-status {
    text-align: center;
  }

  .hero__img {
    margin-left: 1.5rem;
  }

  .hero__img img {
    height: 25rem;
    max-width: initial;
    object-position: left top;
  }

  .hero__lottie {
    left: 2.5rem !important;
    bottom: 8.4rem;
    transform: rotate(-3deg);
  }

  .hero {
    padding-bottom: 34rem;
    height: auto;
    min-height: initial;
  }

  .statistics__wrapper {
    padding-bottom: 0;
  }

  .statistics__content {
    padding-bottom: 3rem;
    max-width: initial;
  }

  .statistics__items {
    position: relative;
    margin: 0 auto;
    max-width: 32rem;
  }

  .statistics__item--first,
  .statistics__item--two,
  .statistics__item--three {
    position: initial;
  }

  .statistics__item--two {
    margin-left: auto;
  }

  .statistics__item {
    max-width: 20rem;
    max-height: 20rem;
  }

  .statistics__item:not(:last-child) {
    margin-bottom: 8rem;
  }

  .statistics__item-title {
    padding-bottom: 2rem;
  }

  .statistics__line-desctop {
    display: none;
  }

  .statistics__line-mobile {
    display: block;
  }

  .for {
    padding-top: 20rem;
    height: initial;
  }

  .start__wrapper::before {
    left: initial;
    right: -15.5rem;
    background-image: url(../img/start/bg-mobile.svg);
  }

  .start__wrapper {
    padding-bottom: 22rem;
  }

  .start__title {
    max-width: initial;
  }

  .start__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column-reverse;
  }

  .start__button {
    align-self: baseline;
  }

  .start__items {
    list-style: decimal;
    margin-left: 2rem;
    padding-bottom: 4rem;
  }

  .start__item:last-child::before {
    display: none;
  }

  .start__item {
    position: initial;
    margin: 0 auto;
    max-width: initial;
    font-size: 1.8rem;
  }

  .start__item:not(:last-child) {
    margin-bottom: 2rem;
  }

  .demo__wrapper::before {
    min-width: 50%;
    min-height: 50%;
  }

  .demo__wrapper {
    padding-top: 3rem;
    padding-bottom: 16rem;
    padding-left: 3rem;
  }

  .demo__title {
    max-width: initial;
  }

  .demo__subtitle {
    max-width: initial;
  }

  .about__text {
    max-width: 65rem;
  }

  .ceo__wrapper::after {
    right: 0;
  }

  .capabilities__wrapper--reverse .capabilities__info {
    margin-right: 4rem;
  }

  .capabilities__wrapper--reverse .capabilities__image {
    margin-left: initial;
  }

  .capabilities__image {
    flex-shrink: 0;
    margin-right: initial;
  }

  .capabilities__info {
    margin-left: 4rem;
    min-width: initial;
  }

  .capabilities__info-title {
    max-width: initial;
  }

  .capabilities__info-list {
    display: block;
    grid-gap: initial;
    max-width: initial;
  }

  .capabilities__info-item:not(:last-child) {
    margin-bottom: 1rem;
  }

  .capabilities__text {
    margin-bottom: 5rem;
    max-width: initial;
  }

  .analyse__tabs-btn:not(:last-child) {
    margin-right: 1.5rem;
  }

  .analyse__content {
    max-width: 55rem;
  }
}

@media (max-width: 62em) {
  .wrapper {
    overflow: hidden;
  }

  .section {
    padding-top: 9rem;
  }

  .breadcrumbs {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }

  .footer {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .footer__wrapper {
    flex-direction: column;
    align-items: baseline;
  }

  .footer__content {
    margin-right: 0;
    margin-left: -1.5rem;
    border-bottom: 1px solid rgb(39, 38, 49);
    padding-bottom: 3rem;
    min-width: calc(100% + 3rem);
    max-width: initial;
  }

  .footer__mobile-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 1.5rem;
    margin-bottom: 4rem;
    margin-left: 1.5rem;
  }

  .footer__logo {
    margin-bottom: 1.6rem;
  }

  .footer__text {
    margin-bottom: 4rem;
    font-size: clamp(
    1.4rem,
    1.3288888889rem + 0.2222222222vw,
    1.6rem
  );
  }

  .footer__social-mobile {
    display: block;
  }

  .footer__nav {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .footer__list {
    flex-direction: column;
  }

  .footer__item:not(:last-child) {
    margin-right: initial;
    margin-bottom: 2.4rem;
  }

  .footer__icons-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 33rem;
  }

  .footer__social {
    padding-top: 4rem;
  }

  .footer__icons {
    display: none;
  }

  .footer__policy {
    flex-direction: column;
    align-items: baseline;
  }

  .footer__info a:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  .footer__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .modal__form {
    padding: 3rem;
  }

  .hero {
    height: initial;
  }

  .hero__title {
    padding-top: 5rem;
  }

  .for .container {
    position: static;
  }

  .for__items {
    grid-template-columns: repeat(2, 1fr);
  }

  .solution__title {
    padding-bottom: 6rem;
  }

  .solution__item {
    height: initial;
  }

  .solution__item-inner {
    flex-direction: column;
  }

  .solution__content {
    margin-bottom: 2.5rem;
  }

  .solution__content-subtitle {
    padding-bottom: 1rem;
  }

  .solution__img {
    width: calc(100% + 3rem);
  }

  .testimonials {
    height: initial;
  }

  .testimonials__wrapper {
    position: static;
  }

  .start {
    margin-top: 10rem;
  }

  .start__wrapper::before {
    right: -5.4rem;
  }

  .partners {
    height: initial;
  }

  .partners .container {
    position: static;
  }

  .demo {
    margin-top: 10rem;
    height: initial;
  }

  .seen {
    margin-bottom: 10rem;
    height: initial;
  }

  .seen .container {
    position: static;
  }

  .seen__items {
    grid-gap: 1.5rem;
  }

  .faq {
    margin-bottom: 10rem;
  }

  .faq__title {
    padding-bottom: 3rem;
  }

  .blog {
    margin-bottom: 10rem;
  }

  .article__wrapper {
    flex-direction: column;
  }

  .article__icons li:not(:last-child) {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }

  .article__icons {
    display: none;
  }

  .article__content {
    padding-bottom: 10rem;
  }

  .price__top {
    display: block;
  }

  .price__button {
    max-width: initial;
  }

  .fee {
    padding-bottom: 10rem;
  }

  .fee__content {
    margin-right: initial;
    margin-bottom: 3rem;
    max-width: initial;
  }

  .about__content {
    flex-direction: column;
  }

  .about__text {
    margin-right: 0;
    max-width: initial;
  }

  .about__img {
    margin-top: 3rem;
    max-width: 25rem;
    text-align: center;
  }

  .our__body:last-child .our__image {
    margin-left: 3rem;
  }

  .our__image {
    margin-right: 3rem;
    max-width: 20rem;
  }

  .ceo {
    margin-top: 9rem;
  }

  .ceo__wrapper {
    flex-direction: column;
  }

  .ceo__img {
    margin-right: auto;
    margin-bottom: 3rem;
    margin-left: auto;
  }

  .ceo__text {
    max-width: initial;
  }

  .ceo__title {
    text-align: center;
  }

  .contact {
    padding-bottom: 10rem;
  }

  .contact__title {
    padding-bottom: 6rem;
  }

  .contact__wrapper {
    flex-direction: column;
  }

  .contact__thanks {
    max-width: initial;
  }

  .contact__form {
    max-width: initial;
  }

  .contact__info {
    margin-top: 3rem;
    margin-left: 0;
  }

  .contact__icons {
    min-height: 14rem;
  }

  .contact .footer__icons {
    display: flex;
  }

  .capabilities__title {
    padding-bottom: 6rem;
  }

  .capabilities__image {
    margin-bottom: 3rem;
  }

  .analyse {
    margin-bottom: 9rem;
  }

  .analyse__tabs-nav {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 3rem;
    border-bottom: unset;
  }

  .analyse__tabs-btn::before {
    bottom: 0;
    height: 0.2rem;
  }

  .analyse__tabs-btn:not(:last-child) {
    margin-right: initial;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    width: max-content;
  }

  .analyse__wrapper {
    flex-direction: column;
  }

  .analyse__content {
    margin-right: initial;
    margin-left: initial;
    max-width: initial;
  }

  .analyse__text:not(:last-child) {
    margin-bottom: 3rem;
  }

  .analyse__img {
    margin-top: 3rem;
  }
}

@media (max-width: 48em) {
  .start__wrapper {
    padding-bottom: 33rem;
  }

  .seen__items {
    grid-template-columns: repeat(2, 1fr);
  }

  .blog__title {
    padding-bottom: 3rem;
  }

  .price__wrapper {
    flex-direction: column;
    padding-bottom: 3rem;
  }

  .price__text {
    margin-right: initial;
    margin-bottom: 3rem;
  }

  .fee__list {
    grid-gap: 2rem;
  }

  .fee__price {
    justify-content: center;
  }

  .fee__info {
    text-align: center;
  }

  .about__title {
    padding-bottom: 6rem;
  }
}

@media (max-width: 36em) {
  .go-up {
    right: 1.5rem;
    top: 5rem;
    bottom: initial;
    min-width: 4.5rem;
    min-height: 4.5rem;
  }

  .header__free,
  .header__sub {
    width: 100%;
    max-width: initial;
  }

  .header__lang {
    margin-right: 0.5rem;
  }

  .hero__lottie {
    bottom: 12.4rem;
    max-width: 24rem;
  }

  .hero {
    padding-bottom: 20rem;
  }

  .testimonials__top {
    flex-direction: column;
    margin-bottom: 4rem;
  }

  .testimonials__navigation {
    align-self: initial;
    margin-right: auto;
    margin-left: auto;
    max-width: 17rem;
  }

  .testimonials__bottom {
    margin-bottom: 2rem;
  }

  .testimonials__slide {
    padding: 2rem;
  }

  .price__section {
    display: none;
  }
}

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}

@media (max-width: 90.625em) {
  .hero__lottie {
    left: calc((100% - var(--container-width) - 10rem) / 2);
  }
}

@media (max-width: 81.25em) {
  .hero::before {
    display: none;
  }

  .hero__img {
    bottom: 0;
  }
}

@media (max-width: 69.375em) {
  .footer__content {
    max-width: 46rem;
  }
}

@media (max-width: 47.9375em) {
  .logo {
    margin-right: 1rem;
  }

  .header__buttons {
    flex-direction: column;
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .header__sub {
    margin-right: initial;
    margin-bottom: 1.5rem;
    width: 100%;
    max-width: initial;
  }

  .header__free {
    width: 100%;
    max-width: initial;
  }

  .header__lang {
    margin-right: 1.5rem;
  }

  .cookies {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .cookies__wrapper {
    flex-direction: column;
    align-items: initial;
  }

  .cookies__inner {
    flex-direction: column;
  }

  .cookies__img {
    margin-right: initial;
    margin-bottom: 1.5rem;
  }

  .cookies__btn {
    margin-top: 2rem;
    margin-left: initial;
  }

  .for__title {
    padding-bottom: 3rem;
  }

  .for__items {
    display: block;
    padding-bottom: 3rem;
  }

  .for__item {
    max-width: initial;
  }

  .for__item + .for__item {
    margin-top: 2.5rem;
  }

  .blog__img {
    max-width: initial;
  }

  .price__item {
    padding: 1rem;
  }

  .price__list li {
    padding-left: 1rem;
  }

  .price__list li::before {
    top: 0.9rem;
    width: 0.3rem;
    height: 0.3rem;
  }

  .price__list li:not(:last-child) {
    margin-bottom: 0.7rem;
  }

  .price__service {
    padding: 2.5rem 1.5rem;
  }

  .price__slide-header {
    padding: 2rem 1.5rem 3rem 1.5rem;
  }

  .price__control,
  .price__control--mobile {
    padding: 2rem;
  }

  .price__footer {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .fee__text + .fee__text {
    margin-top: 1.5rem;
  }

  .our__body:last-child {
    flex-direction: column-reverse;
  }

  .our__body:last-child .our__image {
    margin-left: initial;
  }

  .our__body {
    flex-direction: column-reverse;
  }

  .our__image {
    margin-right: initial;
  }

  .our__content {
    margin-bottom: 3rem;
  }

  .our__list {
    display: block;
    grid-gap: initial;
  }

  .our__item:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .capabilities__wrapper {
    flex-direction: column;
  }

  .capabilities__wrapper--reverse .capabilities__info {
    margin-right: initial;
  }

  .capabilities__wrapper--reverse .capabilities__info::before {
    left: 50%;
    right: initial;
    transform: rotate(89deg);
  }

  .capabilities__image {
    flex-shrink: initial;
  }

  .capabilities__info::before {
    left: 50%;
    top: -3.9rem;
    width: 1.8rem;
    height: 6rem;
    transform: rotate(89deg);
  }

  .capabilities__info {
    margin-left: initial;
  }
}

@media (max-width: 35.9375em) {
  .main__btn {
    min-width: 100%;
  }

  .modal__wrapper {
    padding: 1rem;
  }

  .modal__content {
    padding: 0;
    background-color: var(--clr-default-900);
  }

  .modal .modal__register-content {
    padding: 2.5rem 1.5rem;
  }

  .modal__close::after,
  .modal__close::before {
    background-color: var(--clr-default-100);
  }

  .modal__form {
    padding: 0;
    color: var(--clr-default-100);
    background-color: var(--clr-default-900);
  }

  .modal__title {
    padding-right: 3rem;
  }

  .modal__subtitle {
    color: var(--clr-default-100);
  }

  .thanks .modal__content {
    padding: 2rem;
  }

  .thanks__title {
    padding-right: 2rem;
  }

  .hero__title {
    padding-top: 3rem;
  }

  .hero__button {
    min-width: 100%;
  }

  .statistics__item--three {
    margin-bottom: 7rem;
  }

  .statistics__item {
    max-width: 18rem;
    max-height: 18rem;
  }

  .for {
    padding-top: 15rem;
  }

  .solution__title {
    padding-bottom: 3rem;
  }

  .testimonials__title-wrap {
    margin-right: initial;
    margin-bottom: 4rem;
  }

  .start {
    margin-top: 5rem;
  }

  .start__wrapper::before {
    right: -1.5rem;
  }

  .start__wrapper {
    padding-bottom: 30rem;
  }

  .start__title {
    padding-top: 5rem;
  }

  .start__button {
    margin: 0 auto;
    min-width: 100%;
  }

  .partners__title {
    padding-bottom: 4rem;
  }

  .partners__item img {
    opacity: initial;
  }

  .demo {
    margin-top: 5rem;
    height: auto;
    background-color: var(--clr-primary-400);
  }

  .demo .container {
    position: static;
  }

  .demo__wrapper::before {
    left: 50%;
    right: initial;
    min-width: 36rem;
    min-height: 40rem;
    transform: translateX(-50%);
  }

  .demo__wrapper {
    padding-top: 5rem;
    padding-right: 1rem;
    padding-bottom: 40rem;
    padding-left: 1rem;
  }

  .demo__title {
    font-size: 4rem;
  }

  .demo__button {
    min-width: 100%;
  }

  .seen {
    margin-bottom: 5rem;
  }

  .seen__items {
    display: block;
  }

  .seen__item {
    max-width: initial;
  }

  .seen__item:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .seen__item img {
    opacity: initial;
  }

  .faq {
    margin-bottom: 5rem;
  }

  .faq__wrapper:not(:last-child) {
    margin-bottom: 1rem;
  }

  .faq__question {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 500;
  }

  .blog {
    margin-bottom: 5rem;
  }

  .blog__items {
    grid-gap: 3rem;
  }

  .blog__pagination {
    margin-top: 3rem;
  }

  .blog__pagination-list {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .blog__pagination-item:not(:last-child) {
    margin-right: 0.8rem;
  }

  .article__back {
    margin-top: 6rem;
    margin-bottom: 3rem;
  }

  .article__content {
    padding-bottom: 5rem;
  }

  .article__title {
    word-break: break-word;
  }

  .article__subtitle {
    padding-bottom: 3rem;
  }

  .article__img {
    padding-bottom: 3rem;
  }

  .article__description {
    padding-bottom: 3rem;
  }

  .article__list {
    margin-bottom: 3rem;
  }

  .fee {
    padding-bottom: 5rem;
  }

  .fee__subtitle {
    padding-bottom: 4rem;
  }

  .fee__list {
    padding-bottom: 3rem;
  }

  .fee__content {
    padding-bottom: 3rem;
  }

  .about__title {
    padding-bottom: 3rem;
  }

  .our__body:first-child {
    margin-bottom: 5rem;
  }

  .ceo__wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .ceo__wrapper::before,
  .ceo__wrapper::after {
    width: 4rem;
    height: 4rem;
  }

  .ceo__wrapper::before {
    top: -2rem;
  }

  .ceo__wrapper::after {
    bottom: -2rem;
  }

  .contact {
    padding-bottom: 5rem;
  }

  .contact__title {
    padding-bottom: 4rem;
  }

  .contact__thanks {
    padding: 3rem 0;
    box-shadow: initial;
  }

  .contact__form {
    padding: 3rem 0;
    box-shadow: initial;
  }

  .contact__box {
    background-color: var(--clr-default-300);
  }

  .contact__policy {
    margin-bottom: 2rem;
  }

  .contact__policy-text {
    padding-left: 4rem;
  }

  .contact__phone {
    padding-bottom: 2rem;
  }

  .contact__mail-left {
    padding-bottom: 2rem;
  }

  .capabilities__title {
    padding-bottom: 3rem;
  }

  .capabilities__wrapper {
    margin-bottom: 3rem;
  }

  .capabilities__wrapper--reverse .capabilities__info {
    margin-right: initial;
  }

  .capabilities__info {
    margin-left: initial;
    padding-top: 2rem;
    padding-right: 1.5rem;
    padding-bottom: 2rem;
    padding-left: 1.5rem;
  }

  .capabilities__info-item {
    padding-left: 2rem;
  }

  .capabilities__info-item:before {
    width: 1.5rem;
    height: 1.5rem;
  }

  .capabilities__text {
    margin-bottom: 3rem;
  }

  .analyse {
    margin-bottom: 5rem;
  }

  .analyse__title {
    padding-bottom: 1.5rem;
  }

  .analyse__text:not(:last-child) {
    margin-bottom: 2rem;
  }

  .analyse__text-title {
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 28.75em) {
  .header__lang-dropdown-item a {
    justify-content: center;
  }

  .header__lang-dropdown-item a span {
    display: none;
  }

  .header__lang-img {
    margin-right: initial;
  }

  .header__lang--current span {
    display: none;
  }

  .footer__icons-mobile {
    max-width: initial;
  }

  .footer__icon a svg {
    max-width: 3.8rem;
  }

  .price__slide-wrap {
    max-height: 58rem;
  }
}

@media (max-width: 28.125em) {
  .start__wrapper {
    padding-bottom: 22rem;
  }
}

@media (max-width: 22.5em) {
  .price__slide-wrap {
    max-height: 64rem;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }

  .iti__flag {
    background-image: url("../img/flags/flags@2x.png");
  }

  .iti__flag {
    background-size: 5652px 15px;
  }
}

@media (hover) {
  .go-up:hover:before {
    transform: translate(-50%, -65%);
    transition: transform var(--transition);
  }

  .header__free:hover {
    background-color: var(--clr-primary-100);
    transition: background-color var(--transition);
  }

  .header__lang-dropdown-item a:hover {
    color: #c10fc1;
    transition: color var(--transition);
  }

  .cookies__btn:hover {
    background-color: var(--clr-primary-400);
    transition: background-color var(--transition);
  }

  .blog__pagination-btn:hover {
    background-color: var(--clr-primary-100);
    transition: background-color var(--transition), fill var(--transition);
  }

  .blog__pagination-btn:hover svg {
    fill: var(--clr-default-100);
  }

  .price__control:hover,
  .price__control--mobile:hover {
    background-color: #fbeefb;
    transition: background-color var(--transition);
  }
}
.partners {
  height: 80vh;

  @media (max-width: em(992)) {
    height: initial;
  }

  .container {
    @media (min-width: em(993)) {
      position: sticky;
      top: 4rem;
    }

    @media (max-width: em(992)) {
      position: static;
    }
  }

  &__title {
    padding-bottom: 6rem;

    @media (max-width: em(575)) {
      padding-bottom: 4rem;
    }
  }

  &__items {
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
  }

  &__item {
    min-height: 15rem;
    box-shadow: 1px 1px 25px rgba(70, 70, 70, 0.06);

    &:hover img {
      opacity: initial;
      transition: opacity var(--transition);
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    img {
      opacity: 0.55;
      transition: opacity var(--transition);

      @media (max-width: em(575)) {
        opacity: initial;
      }
    }
  }
}

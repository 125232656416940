.analyse {
  margin-bottom: 20rem;

  @media (max-width: em(992)) {
    margin-bottom: 9rem;
  }

  @media (max-width: em(575)) {
    margin-bottom: 5rem;
  }

  &__tabs-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6rem;
    border-bottom: 0.4rem solid #c4c4c4;

    @media (max-width: em(992)) {
      display: inline-flex;
      flex-direction: column;
      margin-bottom: 3rem;
      border-bottom: unset;
    }
  }

  &__tabs-btn {
    position: relative;
    padding-bottom: 2.5rem;
    font-weight: 600;
    font-size: fluid-font(2rem, 1.6rem);
    line-height: 120%;
    color: var(--clr-default-200);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -0.4rem;
      width: 0;
      height: 0.4rem;
      background-color: var(--clr-primary-100);

      @media (max-width: em(992)) {
        bottom: 0;
        height: 0.2rem;
      }
    }

    &:not(:last-child) {
      margin-right: 5rem;

      @media (max-width: em(1200)) {
        margin-right: 1.5rem;
      }

      @media (max-width: em(992)) {
        margin-right: initial;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        width: max-content;
      }
    }

    &.active {
      color: var(--clr-primary-100);

      &::before {
        width: 100%;
        transition: width var(--transition);
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;

    &--middle {
      flex-direction: row-reverse;
    }

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__content {
    margin-right: 3rem;
    max-width: 70rem;

    &--middle {
      margin-right: initial;
      margin-left: 3rem;
    }

    @media (max-width: em(1200)) {
      max-width: 55rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-left: initial;
      max-width: initial;
    }
  }

  &__title {
    padding-bottom: 6rem;
    font-size: fluid-font(3.6rem, 2.6rem);
    color: var(--clr-primary-200);

    @media (max-width: em(575)) {
      padding-bottom: 1.5rem;
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: 5rem;

      @media (max-width: em(992)) {
        margin-bottom: 3rem;
      }

      @media (max-width: em(575)) {
        margin-bottom: 2rem;
      }
    }
  }

  &__text-title {
    padding-bottom: 2.5rem;
    font-size: fluid-font(2.6rem, 1.8rem);

    @media (max-width: em(575)) {
      padding-bottom: 1.5rem;
    }
  }

  &__text-subtitle {
    line-height: 150%;
    color: var(--clr-default-200);
  }

  &__img {
    max-width: 49rem;

    @media (max-width: em(992)) {
      margin-top: 3rem;
    }

    img {
      border-radius: 2.4rem;
    }
  }

  .hide {
    height: 0;
    visibility: hidden;
  }

  &__list {
    li {
      position: relative;
      padding-left: 1.5rem;
      font-size: 1.6rem;
      line-height: 150%;
      letter-spacing: -0.3px;
      color: rgba(60, 60, 67, 0.85);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 1rem;
        border-radius: 50%;
        width: 0.4rem;
        height: 0.4rem;
        background-color: rgba(60, 60, 67, 0.85);
      }
    }
    li + li {
      margin-top: 1.5rem;
    }
  }
}

.article {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__back {
    position: relative;
    display: block;
    align-self: start;
    margin-top: 8rem;
    margin-bottom: 4rem;
    padding-left: 2.5rem;
    color: var(--clr-default-300);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 1.2rem;
      height: 1.8rem;
      background-image: url(../img/article/back.svg);
      background-size: cover;
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }

    @media (max-width: em(575)) {
      margin-top: 6rem;
      margin-bottom: 3rem;
    }
  }

  &__icons {
    display: flex;
    flex-direction: column;
    li a svg {
      fill: #000;
    }

    li:not(:last-child) {
      margin-bottom: 2rem;

      @media (max-width: em(992)) {
        margin-right: 1.5rem;
        margin-bottom: 0;
      }
    }

    @media (max-width: em(992)) {
      display: none;
    }
  }

  &__content {
    margin: 0 auto;
    padding-bottom: 20rem;
    max-width: 80rem;

    @media (max-width: em(992)) {
      padding-bottom: 10rem;
    }

    @media (max-width: em(575)) {
      padding-bottom: 5rem;
    }
  }

  &__date {
    padding-bottom: 2.5rem;
    letter-spacing: -0.3px;
    color: var(--clr-default-200);
  }

  &__title {
    padding-bottom: 3rem;
    line-height: 111%;

    @media (max-width: em(575)) {
      word-break: break-word;
    }
  }

  &__subtitle {
    padding-bottom: 4.5rem;
    font-size: 2rem;
    letter-spacing: -0.4px;
    color: var(--clr-default-500);

    @media (max-width: em(575)) {
      padding-bottom: 3rem;
    }
  }

  &__img {
    padding-bottom: 5rem;
    max-width: 80rem;

    img {
      border-radius: 0.8rem;
    }

    @media (max-width: em(575)) {
      padding-bottom: 3rem;
    }
  }

  &__description {
    padding-bottom: 5rem;
    line-height: 150%;
    letter-spacing: -0.3px;
    color: var(--clr-default-400);

    @media (max-width: em(575)) {
      padding-bottom: 3rem;
    }
  }

  &__header {
    padding-bottom: 1.6rem;
    font-weight: 700;
    font-size: 2rem;
    color: var(--clr-default-500);
  }

  &__header-description {
    padding-bottom: 1.6rem;
    letter-spacing: -0.3px;
    letter-spacing: -0.3px;
    color: var(--clr-default-400);
  }

  &__list {
    list-style: disc;
    margin-bottom: 5rem;
    margin-left: 1.8rem;
    color: var(--clr-default-400);

    li:not(:last-child) {
      margin-bottom: 2rem;
    }

    li span {
      color: var(--clr-primary-200);
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__bottom {
    color: var(--clr-default-400);

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

.cookies {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  border-radius: 24px 24px 0px 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: 1px -24px 22px rgba(41, 41, 41, 0.14);
  background: var(--clr-default-100);

  &--hide {
    transform: translateY(100%);
    transition: transform var(--transition);
  }

  @media (max-width: em(767)) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(767)) {
      flex-direction: column;
      align-items: initial;
    }
  }

  &__inner {
    display: flex;

    @media (max-width: em(767)) {
      flex-direction: column;
    }
  }

  &__img {
    margin-right: 1.5rem;
    max-width: 5rem;

    @media (max-width: em(767)) {
      margin-right: initial;
      margin-bottom: 1.5rem;
    }
  }

  &__title {
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: fluid-font(2.2rem, 1.8rem);
    color: #1c0b24;
  }

  &__text {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 150%;
    letter-spacing: -0.3px;
    color: rgba(60, 60, 67, 0.85);

    a {
      color: #c10fc1;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.5rem;
    border-radius: 0.8rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    min-width: 29rem;
    min-height: 5rem;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 145%;
    text-align: center;
    color: var(--clr-default-100);
    background-color: #c10fc1;
    transition: background-color var(--transition);

    @media (hover) {
      &:hover {
        background-color: var(--clr-primary-400);
        transition: background-color var(--transition);
      }
    }

    @media (max-width: em(767)) {
      margin-top: 2rem;
      margin-left: initial;
    }
  }
}
